import { isEmpty, some } from 'lodash';
import { UseQueryOptions, useQuery } from 'react-query';

import { useParticipantId } from 'components/Appointments/utilities';
import { ModuleType } from 'lib/analytics/types';
import { ApiError } from 'lib/api/types';

import { AppointmentsData, ResultStatusesData } from './types';

const useResultStatusesQuery = (options?: UseQueryOptions<ResultStatusesData, ApiError>) => {
  return useQuery<ResultStatusesData, ApiError>(
    ['aou_result_statuses', { searchParams: { pid: useParticipantId() } }],
    options
  );
};

const getResultIsReadyForModule = (data: ResultStatusesData | undefined, moduleType: ModuleType) =>
  isEmpty(data?.resultStatuses)
    ? false
    : some(data!.resultStatuses, {
        moduleType,
        resultIsReady: true,
      });

const getResultIsOnHoldForModule = (
  data: ResultStatusesData | undefined,
  moduleType: ModuleType
) =>
  isEmpty(data?.resultStatuses)
    ? false
    : some(data!.resultStatuses, {
        moduleType,
        isOnHold: true,
      });

export const useResultStatuses = () => {
  const { data: resultStatusesData, isLoading: resultStatusesAreLoading } =
    useResultStatusesQuery();
  return {
    resultStatusesAreLoading,
    gemResultsAreReady: getResultIsReadyForModule(resultStatusesData, ModuleType.GEM),
    pgxResultsAreReady: getResultIsReadyForModule(resultStatusesData, ModuleType.PGX),
    hdrResultsAreReady: getResultIsReadyForModule(resultStatusesData, ModuleType.HDR),
    pgxResultsAreOnHold: getResultIsOnHoldForModule(resultStatusesData, ModuleType.PGX),
    hdrResultsAreOnHold: getResultIsOnHoldForModule(resultStatusesData, ModuleType.HDR),
    hdrIsGcBlocked: isEmpty(resultStatusesData?.resultStatuses)
      ? false
      : some(resultStatusesData!.resultStatuses, {
          moduleType: ModuleType.HDR,
          resultIsReady: true,
          isGcBlocked: true,
        }),
  };
};

interface AppointmentsParams {
  type: ModuleType.HDR | ModuleType.PGX;
  previous?: boolean;
  exclude?: string;
}

export const useAppointmentsQuery = (
  params?: AppointmentsParams,
  options?: UseQueryOptions<AppointmentsData, ApiError>
) => {
  return useQuery<AppointmentsData, ApiError>(
    ['aou_appointments', { searchParams: params }],
    options
  );
};
