import {
  ActionLink,
  Box,
  Button,
  Container,
  GpDialog,
  Grid,
  InlineSpacer,
  Spacer,
  Text,
  Title,
  useMediaQuery,
  useTheme,
} from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import { isSameDay, isSameWeek, startOfDay, startOfWeek } from 'date-fns';
import { upperFirst } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { CloseIcon } from 'components/Appointments/CloseIcon';
import { AvailabilityByWeek } from 'components/Appointments/Schedule/AvailabilityByWeek';
import { useParticipantId } from 'components/Appointments/utilities';
import { InformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooter/InformingLoopFooter';
import { ProgressBar } from 'components/common/ProgressBar/ProgressBar';
import { logSchedulingPageView } from 'lib/analytics/analytics';
import { SegmentPageType } from 'lib/analytics/types';

import { IAppointment, Timezones } from '../types';
import { useAvailabilityData, useScheduleAppointmentMutation } from './api';
import { AvailabilityByDay } from './AvailabilityByDay';
import { TimezoneDialog } from './TimezoneDialog';
import { useScheduleNavigationState } from './useScheduleNavigationState/useScheduleNavigationState';

const TEXT_SX = {
  display: 'flex',
  alignItems: 'center',
  margin: 0,
};

interface Props {
  handleScheduleClick: () => void;
  handlePreviousClick: () => void;
  appointment: IAppointment;
  setAppointment: Dispatch<SetStateAction<IAppointment>>;
}

export const Schedule: React.FC<Props> = (props) => {
  const { handleScheduleClick, handlePreviousClick, appointment, setAppointment } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { locale } = usePreferredLocale();
  const theme = useTheme();
  const intl = useIntl();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    incrementDay,
    decrementDay,
    incrementWeek,
    decrementWeek,
    startOfSelectedDay,
    startOfSelectedWeek,
  } = useScheduleNavigationState();
  const startOfCurrentWeek = startOfWeek(new Date());
  const startOfCurrentDay = startOfDay(new Date());

  const handleBackButtonClick = () => {
    // if you have a location key that means you routed in-app
    if (location.key) {
      history.goBack();
    } else {
      // workaround to send back to Page 1, where routing to HDR_APPOINTMENT_SCHEDULING_PATH doesn't work since it's currently technically on that route
      history.go(0);
    }
  };

  // Timezones
  const [timezoneDialogIsOpen, setTimezoneDialogIsOpen] = useState(false);

  const { mutate: scheduleAppointmentMutation } = useScheduleAppointmentMutation(
    useParticipantId() ?? undefined
  );
  const { data: availabilityData, isLoading: appointmentAvailabilitiesLoading } =
    useAvailabilityData(
      appointment.type,
      appointment.location,
      appointment.language,
      startOfSelectedWeek.toISOString()
    );

  const appointmentAvailabilities = (availabilityData?.availabilities || []).map(
    (isoString: string) => new Date(isoString)
  );
  const appointmentAvailabilitiesOnSelectedDay = appointmentAvailabilities.filter(
    (appointmentDate) => isSameDay(appointmentDate, startOfSelectedDay)
  );
  const appointmentAvailabilitiesOnSelectedWeek = appointmentAvailabilities.filter(
    (appointmentDate) => isSameWeek(appointmentDate, startOfSelectedWeek)
  );

  // nosemgrep:semgrep-rules.ui-useEffect
  useEffect(() => {
    logSchedulingPageView(appointment.type, SegmentPageType.GC_SCHEDULING_CALENDAR);
  }, [appointment]);

  return (
    <GpDialog fullScreen open>
      <ProgressBar currentStep={2} numSteps={2} />
      <Grid container justifyContent="flex-end">
        <CloseIcon
          aria-label={intl.formatMessage({ id: 'Scheduling_Schedule__Button__Back' })}
          onClick={handleBackButtonClick}
        />
      </Grid>
      <Spacer variant="small" />
      <Container>
        <Title variant="headline1">
          <FormattedMessage id="Scheduling_Schedule__Title" />
        </Title>
        <Spacer variant="small" />
        <Text>
          <FormattedMessage
            id="Scheduling_Schedule__Text"
            values={{ currentTimezone: Timezones[appointment.timezone as keyof typeof Timezones] }}
          />
        </Text>
        <InlineSpacer variant="xSmall" />
        <ActionLink onClick={() => setTimezoneDialogIsOpen(true)} variant="body1">
          <FormattedMessage id="Schedule_Schedule__Link__Change_Timezone" />
        </ActionLink>
        <TimezoneDialog
          currentTimezone={appointment.timezone}
          setAppointment={setAppointment}
          dialogIsOpen={timezoneDialogIsOpen}
          setDialogIsOpen={setTimezoneDialogIsOpen}
        />
        <Box width="90%" margin="5%">
          {isMobile ? (
            <>
              <Grid container justifyContent="space-between">
                <Grid>
                  <Button
                    disabled={startOfSelectedDay.getTime() === startOfCurrentDay.getTime()}
                    variant="color-secondary"
                    onClick={decrementDay}
                  >
                    <FormattedMessage id="Scheduling_Schedule__Button__Last_Week" />
                  </Button>
                  <InlineSpacer variant="medium" />
                  <Button variant="color-secondary" onClick={incrementDay}>
                    <FormattedMessage id="Scheduling_Schedule__Button__Next_Week" />
                  </Button>
                </Grid>
                <Title variant="headline3" sx={TEXT_SX}>
                  {startOfSelectedDay
                    .toLocaleString(locale, { weekday: 'short', month: 'long', day: '2-digit' })
                    .replace(/,/g, '')}
                </Title>
              </Grid>
              <Spacer variant="medium" />
              <AvailabilityByDay
                appointment={appointment}
                setAppointment={setAppointment}
                appointmentAvailabilities={appointmentAvailabilitiesOnSelectedDay}
                startOfSelectedDay={startOfSelectedDay}
                nextDay={incrementDay}
                appointmentAvailabilitiesLoading={appointmentAvailabilitiesLoading}
              />
            </>
          ) : (
            <>
              <Grid container justifyContent="space-between">
                <Grid>
                  <Button
                    disabled={startOfCurrentWeek.getTime() === startOfSelectedWeek.getTime()}
                    variant="color-secondary"
                    onClick={decrementWeek}
                  >
                    <FormattedMessage id="Scheduling_Schedule__Button__Last_Week" />
                  </Button>
                  <InlineSpacer variant="medium" />
                  <Button variant="color-secondary" onClick={incrementWeek}>
                    <FormattedMessage id="Scheduling_Schedule__Button__Next_Week" />
                  </Button>
                </Grid>
                {/* TODO: switching weeks and this month description: ALLOFUS-1881 */}
                <Title variant="headline3" sx={TEXT_SX}>
                  {upperFirst(
                    startOfSelectedDay.toLocaleString(locale, {
                      month: 'long',
                      year: 'numeric',
                    })
                  ).replace('de', '')}
                </Title>
              </Grid>
              <Spacer variant="medium" />
              <AvailabilityByWeek
                appointment={appointment}
                setAppointment={setAppointment}
                startOfSelectedWeek={startOfSelectedWeek}
                appointmentAvailabilities={appointmentAvailabilitiesOnSelectedWeek}
                nextWeek={incrementWeek}
                appointmentAvailabilitiesLoading={appointmentAvailabilitiesLoading}
              />
            </>
          )}
        </Box>
      </Container>
      <Spacer variant="large" />
      <InformingLoopFooter
        leftButton={
          <Button variant="color-secondary" onClick={handlePreviousClick}>
            <FormattedMessage id="Scheduling_Schedule__Button__Back" />
          </Button>
        }
        rightButton={
          <Button
            variant="color-primary"
            disabled={appointment.timestamp === undefined || loading}
            onClick={() => {
              setLoading(true);

              scheduleAppointmentMutation(appointment, {
                onSuccess: () => {
                  // update state so that final page is displayed
                  handleScheduleClick();
                },
                onError: () => {
                  // TODO ALLOFUS-1975: display error to user noting appointment unavailable
                  // and refresh display of available appointments.
                  setLoading(false);
                },
              });
            }}
          >
            <FormattedMessage id="Scheduling_Schedule__Button__Next" />
          </Button>
        }
      />
    </GpDialog>
  );
};
