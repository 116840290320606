import { HdrV1ReportPayload } from '@color/aou-reports';
import { Redirect } from 'react-router-dom';

import { ModuleType } from 'lib/analytics/types';

import { useHealthResultsQuery } from '../api';
import { HdrPositiveReportPreScreen } from './HdrPositiveReport/HdrPositiveReportPrescreen/HdrPositiveReportPreScreen';
import { HdrUninformativeReport } from './HdrUninformativeReport/HdrUninformativeReport';

/**
 * HDR reports can optionally be accessed with token that is generated by
 * clinical and allows GCs to view participant web results.
 *
 * If no token is provided, the HDR report will default to authenticating the
 * participant and showing the particpant's HDR results.
 */
interface Props {
  token?: string;
}

export const HdrReport: React.FC<Props> = (props) => {
  const { token } = props;
  const {
    data: report,
    isLoading,
    isError,
  } = useHealthResultsQuery({
    moduleType: ModuleType.HDR,
    token,
  });
  if (isError) {
    return <Redirect to="/" />;
  }
  if (isLoading || !report) {
    return null;
  }
  if (report.components.variantDetails.length === 0) {
    return <HdrUninformativeReport report={report} token={token} />;
  }
  return <HdrPositiveReportPreScreen report={report as HdrV1ReportPayload} token={token} />;
};
