import {
  Box,
  Card,
  CardContent,
  CardProps,
  Grid,
  InternalButtonLink,
  ReportIcon,
  Spacer,
  Text,
  Title,
} from '@color/continuum';
import { Location } from 'history';
import { ReactNode } from 'react';

import { ReportIconType } from '../Reports/ReportIcon/types';
import { getReportIconSrc } from '../Reports/ReportIcon/utils';

export interface ResultCardProps extends CardProps {
  title: string;
  subtitle?: JSX.Element | ReactNode;
  icon: ReportIconType;
  buttonLinkTo: string | { pathname: string; state: { backgroundLocation: Location } };
  buttonText?: string;
  buttonAriaLabel?: string;
  resultIsViewed: boolean;
}

export const ResultCard: React.FC<ResultCardProps> = (props: ResultCardProps) => {
  const { title, subtitle, icon, buttonLinkTo, buttonText, buttonAriaLabel, resultIsViewed } =
    props;

  return (
    <Card>
      <CardContent>
        <Box display="flex" gap={3}>
          <ReportIcon iconSrc={getReportIconSrc(icon)} />
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item component={Box} xs={12} sm alignSelf="center">
              <Title variant="headline3">{title}</Title>
              <Spacer variant="xSmall" />
              <Text>{subtitle}</Text>
            </Grid>
            {buttonText && (
              <Grid item component={Box} alignSelf="center">
                <InternalButtonLink
                  to={buttonLinkTo}
                  variant={resultIsViewed ? 'color-secondary' : 'color-primary'}
                  aria-label={buttonAriaLabel}
                >
                  {buttonText}
                </InternalButtonLink>
              </Grid>
            )}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};
