import { Banner, Spacer } from '@color/continuum';
import { PropsWithChildren } from 'react';

export const ResearchResultWarningBanner: React.FC<PropsWithChildren<{}>> = (props) => {
  const { children } = props;
  return (
    <>
      <Spacer variant="medium" />
      <Banner severity="warning" variant="in-component">
        {children}
      </Banner>
      <Spacer variant="medium" />
    </>
  );
};
