import { ExternalLink } from '@color/continuum';
import { ReactNode } from 'react';

export interface GeneReferences {
  gene: string;
  refrences: ReactNode[];
}
export const REFERENCES: GeneReferences[] = [
  {
    gene: 'BRCA1',
    refrences: [
      'Chen, J., Bae, E., Zhang, L., Hughes, K., Parmigiani, G., Braun, D., & Rebbeck, T. R. (2020). Penetrance of Breast and Ovarian Cancer in Women Who Carry a BRCA1/2 Mutation and Do Not Use Risk-Reducing Salpingo-Oophorectomy: An Updated Meta-Analysis. JNCI Cancer Spectrum, 4(4), kaa029.',
      <>
        Surveillance, Epidemiology, and End Results (SEER) Program, National Cancer Institute.
        2017-2019. DevCan software (
        <ExternalLink to="http://surveillance.cancer.gov/devcan">
          http://surveillance.cancer.gov/devcan
        </ExternalLink>
        ) V 6.8.0. Accessed April 2022.
      </>,
      'Hu C, Hart SN, Polley EC, et al. Association Between Inherited Germline Mutations in Cancer Predisposition Genes and Risk of Pancreatic Cancer. JAMA. 2018;319(23):2401-2409.',
      'Mocci E, Milne RL, Mendez-Villamil EY, et al. Risk of pancreatic cancer in breast cancer families from the breast cancer family registry. Cancer Epidemiology Biomarkers Prev. May 2013;22(5):803-11.',
      'Tai YC, Domchek S, Parmigiani G, Chen s. Breast cancer risk among male BRCA1 and BRCA2 mutation carriers. J Natl Cancer Inst. December 2007;99(23):1811-4.',
      'Liede A, Karlan BY, Narod SA. Cancer risks for male carriers of germline mutations in BRCA1 or BRCA2: a review of the literature. J Clin Oncol. February 2014;22(4):735-42.',
      'Leongamornlert D, Mahmud N, Tymrakiewicz M, et al. Germline BRCA1 mutations increase prostate cancer risk. Br J Cancer. May 2012;106(10)1687-701.',
      'Goggins M, Overbeek KA, Brand R, et al. Management of patients with increased risk for familial pancreatic cancer: updated recommendations from the International Cancer of the Pancreas Screening (CAPS) Consortium. Gut. 2019; 69(1):7-17.',
      'Mavaddat N, Peock S, Frost D, et al. Cancer risks for BRCA1 and BRCA2 mutation carriers: results from prospective analysis of EMBRACE. J Natl Cancer Inst. 2013;105(11):812-22.',
      <>
        National Comprehensive Cancer Network. Genetic/Familial High-Risk Assessment: Breast,
        Ovarian, and Pancreatic. NCCN Guidelines Version 2.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published March 2022.
      </>,
      <>
        National Comprehensive Cancer Network. Prostate Cancer Early Detection. NCCN Guidelines
        Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published February
        2022.
      </>,
      <>
        Petrucelli N, Daly MB, Pal T. BRCA1- and BRCA2-Associated Hereditary Breast and Ovarian
        Cancer. 1998 Sep 4 [Updated 2022 Feb 3]. In: Adam MP, Ardinger HH, Pagon RA, et al.,
        editors. GeneReviews® [Internet]. Seattle (WA): University of Washington, Seattle;
        1993-2022. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1247/">
          https://www.ncbi.nlm.nih.gov/books/NBK1247/
        </ExternalLink>
      </>,
    ],
  },
  {
    gene: 'BRCA2',
    refrences: [
      'Chen J, Bae E, Zhang L, et al. Penetrance of breast and ovarian cancer in women who carry a brca1/2 mutation and do not use risk-reducing salpingo-oophorectomy: an updated meta-analysis. JNCI Cancer Spectr. 2020;4(4):pkaa029.',
      'Liede A, Karlan BY, Narod SA. Cancer risks for male carriers of germline mutations in BRCA1 or BRCA2: a review of the literature. J Clin Oncol. February 2004;22(4):735-42.',
      'van Asperen CJ, Brohet RM, Meijers-Heijboer EJ, et al. Cancer risks in BRCA2 families: estimates for sites other than breast and ovary. J Med Genet. September 2005;42(9):711-9.',
      'Thompson D, Easton D: Variation in cancer risks, by mutation position, in BRCA2 mutation carriers. Am J Hum Genet. 2001;68:410-419.',
      'Tai YC, Domchek S, Parmigiani G, Chen S. Breast cancer risk among male BRCA1 and BRCA2 mutation carriers. J Natl Cancer Inst. December 2007; 99(23):1811-4.',
      'Liede A, Karlan BY, Narod SA. Cancer risks for male carriers of germline mutations in BRCA1 or BRCA2: a review of the literature. J Clin Oncol. February 2014;22(4):735-42.',
      'Cancer risks in BRCA2 mutation carriers. J Natl Cancer Inst. 1999;91(15):1310-6.',
      'Gumaste PV, Penn LA, Cymerman RM, Kirchhoff T, Polsky D, Mclellan B. Skin cancer risk in BRCA1/2 mutation carriers. Br J Dermatol. 2015;172(6):1498-506.',
      'Mocci E, Milne RL, Mendez-Villamil EY, et al. Risk of pancreatic cancer in breast cancer families from the breast cancer family registry. Cancer Epidemiology Biomarkers Prev. May 2013;22(5)803-11.',
      'van Asperen CJ, Brohet RM, Meijers-heijboer EJ, et al. Cancer risks in BRCA2 families: estimates for sites other than breast and ovary. J Med Genet. 2005;42(9):711-9.',
      'Iqbal J, Ragone A, Lubinski J, et al. The incidence of pancreatic cancer in BRCA1 and BRCA2 mutation carriers. Br J Cancer. 2012;107(12):2005-9.',
      'Hu C, Hart SN, Polley EC, et al. Association Between Inherited Germline Mutations in Cancer Predisposition Genes and Risk of Pancreatic Cancer. JAMA. 2018;319(23):2401-2409.',
      <>
        SEER*Explorer: An interactive website for SEER cancer statistics [Internet]. Surveillance
        Research Program, National Cancer Institute. [Cited 2022 July 17]. Available from{' '}
        <ExternalLink to="https://seer.cancer.gov/statistics-network/explorer/">
          https://seer.cancer.gov/statistics-network/explorer/
        </ExternalLink>
        .
      </>,
      <>
        National Comprehensive Cancer Network. Genetic/Familial High-Risk Assessment: Breast,
        Ovarian, and Pancreatic. NCCN Guidelines Version 2.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published March 2022.
      </>,
      <>
        National Comprehensive Cancer Network. Prostate Cancer Early Detection. NCCN Guidelines
        Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published February
        2022.
      </>,
    ],
  },
  {
    gene: 'MSH2',
    refrences: [
      'Bonadona V, Bonaiti B, Olschwang S, et al. Cancer risks associated with germline mutations in MLH1, MSH2, and MSH6 genes in Lynch syndrome. JAMA. June 2011; 305(22):2304-10.',
      'Moller P, Seppala TT, Bernstein I, et al. Cancer risk and survival in path_MMR carriers by gene and gender up to 75 years of age: a report from the Prospective Lynch Syndrome Database. Gut 2018;67:1306-1316.',
      'Ryan NAJ, Morris J, Green K, et al. Association of mismatch repair mutation with age at cancer onset in Lynch syndrome: Implications for stratified surveillance strategies. JAMA Oncol 2017;3:1702-1706.',
      'Moller P, Seppala T, Bernstein I, et al. Cancer incidence and survival in Lynch syndrome patients receiving colonoscopic and gynaecological surveillance: first report from the prospective Lynch syndrome database. Gut 2017;66:464-472.',
      'Dominguez-Valentin M, Sampson J, Seppälä T, et al. Cancer risks by gene, age, and gender in 6350 carriers of pathogenic mismatch repair variants: findings from the Prospective Lynch Syndrome Database. Genet Med 2020;22:15-25.',
      'Engel C, Loeffler M, Steinke V, et al. Risks of less common cancers in proven mutation carriers with lynch syndrome. J Clin Oncol 2012;30:4409-4415.',
      'Watson P, Vasen HFA, Mecklin JP, et al. The risk of extra-colonic, extra-endometrial cancer in the Lynch syndrome. Int J Cancer 2008;123:444-449.',
      'South CD, Hampel H, Comeras I, et al. The frequency of Muir-Torre syndrome among Lynch syndrome families. JNCI. February 2008; 100(4):277-81.',
      'Adan F, Crijns MB, Zandstra WSE, et al. Cumulative risk of skin tumors in patients with Lynch syndrome. Br J Dermatol 2018;179:522-523.',
      'Joost P, Therkildsen C, Dominguez-Valentin M, et al. Urinary tract cancer in lynch syndrome; increased risk in carriers of MSH2 mutations. Urology 2015;86:1212-1217.',
      'Dominguez-Valentin M, Joost P, Therkildsen C, et al. Frequent mismatch-repair defects link prostate cancer to Lynch syndrome. BMC Urol 2016;16:15.',
      <>
        SEER*Explorer: An interactive website for SEER cancer statistics [Internet]. Surveillance
        Research Program, National Cancer Institute. [Cited 2022 July 17]. Available from{' '}
        <ExternalLink to="https://seer.cancer.gov/statistics-network/explorer/">
          https://seer.cancer.gov/statistics-network/explorer/
        </ExternalLink>
        .
      </>,
      <>
        National Comprehensive Cancer Network. Genetic/Familial High-Risk Assessment: Colorectal.
        NCCN Guidelines Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published June 2022.
      </>,
      <>
        National Comprehensive Cancer Network. Genetic/Familial High-Risk Assessment: Breast,
        Ovarian, and Pancreatic. NCCN Guidelines Version 2.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published March 2022.
      </>,
      <>
        National Comprehensive Cancer Network. Prostate Cancer Early Detection. NCCN Guidelines
        Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published February
        2022.
      </>,
      'Goggins M, Overbeek KA, Brand R, et al. Management of patients with increased risk for familial pancreatic cancer: updated recommendations from the International Cancer of the Pancreas Screening (CAPS) Consortium. Gut. 2019;69(1):7-17.',
    ],
  },
  {
    gene: 'MLH1',
    refrences: [
      'Bonadona V, Bonaiti B, Olschwang S, et al. Cancer risks associated with germline mutations in MLH1, MSH2, and MSH6 genes in Lynch syndrome. JAMA. June 2011; 305(22):2304-10.',
      'Moller P, Seppala TT, Bernstein I, et al. Cancer risk and survival in path_MMR carriers by gene and gender up to 75 years of age: a report from the Prospective Lynch Syndrome Database. Gut 2018;67:1306-1316.',
      'Ryan NAJ, Morris J, Green K, et al. Association of mismatch repair mutation with age at cancer onset in Lynch syndrome: Implications for stratified surveillance strategies. JAMA Oncol 2017;3:1702-1706.',
      'Moller P, Seppala T, Bernstein I, et al. Cancer incidence and survival in Lynch syndrome patients receiving colonoscopic and gynaecological surveillance: first report from the prospective Lynch syndrome database. Gut 2017;66:464-472.',
      'Engel C, Loeffler M, Steinke V, et al. Risks of less common cancers in proven mutation carriers with lynch syndrome. J Clin Oncol 2012;30:4409-4415.',
      'Dominguez-Valentin M, Sampson J, Seppälä T, et al. Cancer risks by gene, age, and gender in 6350 carriers of pathogenic mismatch repair variants: findings from the Prospective Lynch Syndrome Database. Genet Med 2020;22:15-25.',
      'Watson P, Vasen HFA, Mecklin JP, et al. The risk of extra-colonic, extra-endometrial cancer in the Lynch syndrome. Int J Cancer 2008;123:444-449.',
      'South CD, Hampel H, Comeras I, et al. The frequency of Muir-Torre syndrome among Lynch syndrome families. JNCI. February 2008; 100(4):277-81.',
      'Adan F, Crijns MB, Zandstra WSE, et al. Cumulative risk of skin tumors in patients with Lynch syndrome. Br J Dermatol 2018;179:522-523.',
      'Engel C, Loeffler M, Steinke V, et al. Risks of less common cancers in proven mutation carriers with lynch syndrome. J Clin Oncol 2012;30:4409-4415.',
      'Capelle L, van Grieken N, Lingsma H, et al. Risk and epidemiological time trends of gastric cancer in Lynch syndrome carriers in the Netherlands. Gastroenterology 2010;138:487-492.',
      'Joost P, Therkildsen C, Dominguez-Valentin M, et al. Urinary tract cancer in lynch syndrome; increased risk in carriers of MSH2 mutations. Urology 2015;86:1212-1217.',
      'Dominguez-Valentin M, Joost P, Therkildsen C, et al. Frequent mismatch-repair defects link prostate cancer to Lynch syndrome. BMC Urol 2016;16:15.',
      <>
        SEER*Explorer: An interactive website for SEER cancer statistics [Internet]. Surveillance
        Research Program, National Cancer Institute. [Cited 2022 July 17]. Available from{' '}
        <ExternalLink to="https://seer.cancer.gov/statistics-network/explorer/">
          https://seer.cancer.gov/statistics-network/explorer/
        </ExternalLink>
        .
      </>,
      <>
        National Comprehensive Cancer Network. Genetic/Familial High-Risk Assessment: Colorectal.
        NCCN Guidelines Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published June 2022.
      </>,
      <>
        National Comprehensive Cancer Network. Genetic/Familial High-Risk Assessment: Breast,
        Ovarian, and Pancreatic. NCCN Guidelines Version 2.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published March 2022.
      </>,
      <>
        National Comprehensive Cancer Network. Prostate Cancer Early Detection. NCCN Guidelines
        Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published February
        2022.
      </>,
      'Goggins M, Overbeek KA, Brand R, et al. Management of patients with increased risk for familial pancreatic cancer: updated recommendations from the International Cancer of the Pancreas Screening (CAPS) Consortium. Gut. 2019;69(1):7-17.',
    ],
  },
  {
    gene: 'APC',
    refrences: [
      'Half E, Bercovich, D, Rozen. Familial adenomatous polyposis. Orphanet J Rare Dis 2009;4:22.',
      'Bülow S. Results of national registration of familial adenomatous polyposis. Gut 2003;52:742-746.',
      'Attard TM, Giglio P, Koppula S, Snyder C, Lynch HT. Brain tumors in individuals with familial adenomatous polyposis: a cancer registry experience and pooled case report analysis. Cancer. 2007;109(4):761-6.',
      'de Oliveira JC, Viana DV, Zanardo C, et al. Genotype-phenotype correlation in 99 familial adenomatous polyposis patients: A prospective prevention protocol. Cancer Medicine 2019;8:2114-2122.',
      'Church J, Xhaja X, LaGuardia L et al. Desmoids and genotype in familial adenomatous polyposis. Dis Colon Rectum 2015;58:444-448.',
      'Sturt NJ, Gallagher MC, Bassett P, et al. Evidence for genetic predisposition to desmoid tumours in familial adenomatous polyposis independent of the germline APC mutation. Gut. 2004;53(12):1832-6.',
      'Giardiello FM, Offerhaus GJ, Lee DH, et al. Increased risk of thyroid and pancreatic carcinoma in familial adenomatous polyposis. Gut. 1993;34(10):1394-6.',
      'Bülow S, Björk J, Christensen IJ, et al. Duodenal adenomatosis in familial adenomatous polyposis. Gut. 2004;53(3):381-6.',
      'Singh AD, Bhatt A, Joseph A, et al. The natural history of ampullary adenomas in familial adenomatous polyposis: a long-term follow-up study. Gastrointest Endosc 2022;95:455-467.',
      'Jasperson KW, Tuohy TM, Neklason DW, Burt RW. Hereditary and familial colon cancer. Gastro. 2010 Jun;138(6):2044-58.',
      'Mankaney G, Leone P, Cruise M, et al. Gastric cancer in FAP: a concerning rise in incidence. Fam Cancer 2017;16:371-376.',
      'Wood L, Salaria SN, Cruise MW, et al. Upper GI tract lesions in familial adenomatous polyposis (FAP): Enrichment of pyloric gland adenomas and other gastric and duodenal neoplasms. Am J Surg Pathol 2014;38:389-393.',
      'Chenbhanich J, Atsawarungruangkit A, Korpaisarn S, et al. Prevalence of thyroid diseases in familial adenomatous polyposis: a systematic review and meta-analysis. Fam Cancer 2019;18:53-62.',
      <>
        SEER*Explorer: An interactive website for SEER cancer statistics [Internet]. Surveillance
        Research Program, National Cancer Institute. [Cited 2022 July 17]. Available from{' '}
        <ExternalLink to="https://seer.cancer.gov/statistics-network/explorer/">
          https://seer.cancer.gov/statistics-network/explorer/
        </ExternalLink>
        .
      </>,
      <>
        National Comprehensive Cancer Network. Genetic/Familial High-Risk Assessment: Colorectal.
        NCCN Guidelines Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published June 2022.
      </>,
    ],
  },
  {
    gene: 'MSH6',
    refrences: [
      'Bonadona V, Bonaiti B, Olschwang S, et al. Cancer risks associated with germline mutations in MLH1, MSH2, and MSH6 genes in Lynch syndrome. JAMA 2011;305:2304-2310.',
      'Moller P, Seppala TT, Bernstein I, et al. Cancer risk and survival in path_MMR carriers by gene and gender up to 75 years of age: a report from the Prospective Lynch Syndrome Database. Gut 2018;67:1306-1316.',
      'Baglietto L, Lindor NM, Dowty JG, et al. Risks of Lynch syndrome cancers for MSH6 mutation carriers. J Natl Cancer Inst 2010;102:193-201.',
      'Suerink M, Rodriguez-Girondo M, van der Klift HM, et al. An alternative approach to establishing unbiased colorectal cancer risk estimation in Lynch syndrome. Genet Med 2019;21;2706-2712.',
      'Ryan N, Morris J, Green K, et al. Association of mismatch repair mutation with age at cancer onset in Lynch syndrome: Implications for Stratified Surveillance Strategies. JAMA Oncol 2017;3:1702-1706.',
      'Dominguez-Valentin M, Sampson J, Seppälä T, et al. Cancer risks by gene, age, and gender in 6350 carriers of pathogenic mismatch repair variants: findings from the Prospective Lynch Syndrome Database. Genet Med 2020;22:15-25.',
      'Engel C, Loeffler M, Steinke V, et al. Risks of less common cancers in proven mutation carriers with lynch syndrome. J Clin Oncol 2012;30:4409-4415.',
      'Joost P, Therkildsen C, Dominguez-Valentin M, et al. Urinary tract cancer in lynch syndrome; increased risk in carriers of MSH2 mutations. Urology 2015;86:1212-1217.',
      'Haraldsdottir S, Rafnar T, Frankel WL, et al. Comprehensive population-wide analysis of Lynch syndrome in Iceland reveals founder mutations in MSH6 and PMS2. Nature Comm 2017;8:14755.',
      'South CD, Hampel H, Comeras I, et al. Frequency of Muir-Torre syndrome among Lynch syndrome families. J Natl Cancer Inst 2008;100:277-281.',
      'Adan F, Crijns MB, Zandstra WSE, et al. Cumulative risk of skin tumors in patients with Lynch syndrome. Br J Dermatol 2018;179:522-523.',
      <>
        SEER*Explorer: An interactive website for SEER cancer statistics [Internet]. Surveillance
        Research Program, National Cancer Institute. [Cited 2022 July 17]. Available from{' '}
        <ExternalLink to="https://seer.cancer.gov/statistics-network/explorer/">
          https://seer.cancer.gov/statistics-network/explorer/
        </ExternalLink>
        .
      </>,
      <>
        National Comprehensive Cancer Network. Genetic/Familial High-Risk Assessment: Colorectal.
        NCCN Guidelines Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published June 2022.
      </>,
      <>
        National Comprehensive Cancer Network. Genetic/Familial High-Risk Assessment: Breast,
        Ovarian, and Pancreatic. NCCN Guidelines Version 2.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published March 2022.
      </>,
      <>
        National Comprehensive Cancer Network. Prostate Cancer Early Detection. NCCN Guidelines
        Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published February
        2022.
      </>,
      'Goggins M, Overbeek KA, Brand R, et al. Management of patients with increased risk for familial pancreatic cancer: updated recommendations from the International Cancer of the Pancreas Screening (CAPS) Consortium. Gut. 2019;69(1):7-17.',
    ],
  },
  {
    gene: 'TP53',
    refrences: [
      'Coffee B, Cox HC, Kidd J, et al. Detection of somatic variants in peripheral blood lymphocytes using a next generation sequencing multigene pan cancer panel. Cancer Genet. 2017;211:5-8.',
      'Weitzel JN, Chao EC, Nehoray B, et al. Somatic TP53 variants frequently confound germ-line testing results. Genet Med. 2017.',
      'Hwang SJ, Lozano G, Amos CI, Strong LC. Germline p53 mutations in a cohort with childhood sarcoma: sex differences in cancer risk. Am J Hum Genet. 2003;72(4):975-83.',
      'Ruijs MW, Verhoef S, Rookus MA, et al. TP53 germline mutation testing in 180 families suspected of Li-Fraumeni syndrome: mutation detection rate and relative frequency of cancers in different familial phenotypes. J Med Genet. 2010;47(6):421-8.',
      'Olivier M, Goldgar DE, Sodha N, et al. Li-Fraumeni and related syndromes: correlation between tumor type, family structure, and TP53 genotype. Cancer Res. 2003;63(20):6643-50.',
      'Mai PL, Best AF, Peters JA, et al. Risks of first and subsequent cancers among TP53 mutation carriers in the National Cancer Institute Li-Fraumeni syndrome cohort. Cancer. 2016;122(23):3673-3681.',
      <>
        SEER*Explorer: An interactive website for SEER cancer statistics [Internet]. Surveillance
        Research Program, National Cancer Institute. [Cited 2022 July 17]. Available from{' '}
        <ExternalLink to="https://seer.cancer.gov/statistics-network/explorer/">
          https://seer.cancer.gov/statistics-network/explorer/
        </ExternalLink>
      </>,
      'Villani A, Shore A, Wasserman JD, et al. Biochemical and imaging surveillance in germline TP53 mutation carriers with Li-Fraumeni syndrome: 11 year follow-up of a prospective observational study. Lancet Oncol. 2016;17(9):1295-305.',
    ],
  },
  {
    gene: 'PTEN',
    refrences: [
      'Hendricks LAJ, Hoogerbrugge N, Schuurs-Hoeijmakers JHM, Vos JR. A review on age-related cancer risks in PTEN hamartoma tumor syndrome. Clin Genet 2021;99:219-225.',
      'Pilarski R. PTEN hamartoma tumor syndrome: a clinical overview. Cancers (Basel) 2019;11:844.',
      'Pilarski R. Cowden syndrome: A critical review of the clinical literature. J Genet Couns 2009;18:13-27.',
      'Pilarski R, Burt R, Kohlman W, et al. Cowden syndrome and the PTEN hamartoma tumor syndrome: systematic review and revised diagnostic criteria. J Natl Cancer Inst 2013;105:1607-1616.',
      'Bubien V, Bonnet F, Brouste V, et al. High cumulative risks of cancer in patients with PTEN hamartoma tumour syndrome. J Med Genet 2013;50:255-263.',
      'Tan MH, Mester JL, Ngeow J, et al. Lifetime cancer risks in individuals with germline PTEN mutations. Clin Cancer Res 2012;18:400-407.',
      <>
        SEER*Explorer: An interactive website for SEER cancer statistics [Internet]. Surveillance
        Research Program, National Cancer Institute. [Cited 2022 July 17]. Available from{' '}
        <ExternalLink to="https://seer.cancer.gov/statistics-network/explorer/">
          https://seer.cancer.gov/statistics-network/explorer/
        </ExternalLink>
        .
      </>,
      <>
        National Comprehensive Cancer Network. Genetic/Familial High-Risk Assessment: Colorectal.
        NCCN Guidelines Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published June 2022.
      </>,
      <>
        National Comprehensive Cancer Network. Genetic/Familial High-Risk Assessment: Breast,
        Ovarian, and Pancreatic. NCCN Guidelines Version 2.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published March 2022.
      </>,
    ],
  },
  {
    gene: 'PMS2',
    refrences: [
      'Senter L, Clendenning M, Sotamaa K, et al. The clinical phenotype of Lynch syndrome due to germ-line PMS2 mutations. Gastroenterology 2008;135:419428.',
      'Ten Broeke SW, van der Klift HM, Tops CMJ, et al. Cancer risks for PMS2 associated Lynch syndrome. J Clin Oncol 2018;36:2961-2968.',
      'Suerink M, et al. An alternative approach to establishing unbiased colorectal cancer risk estimation in Lynch syndrome. Genet Med 2019; 21;2706-2712.',
      'Moller P, Seppala TT, Bernstein I, et al. Cancer risk and survival in path_MMR carriers by gene and gender up to 75 years of age: a report from the Prospective Lynch Syndrome Database. Gut 2018;67:1306-1316.',
      'Moller P, Seppala T, Bernstein I, et al. Cancer incidence and survival in Lynch syndrome patients receiving colonoscopic and gynaecological surveillance: first report from the prospective Lynch syndrome database. Gut 2017;66:464-472.',
      'Dominguez-Valentin M, Sampson J, Seppälä T, et al. Cancer risks by gene, age, and gender in 6350 carriers of pathogenic mismatch repair variants: findings from the Prospective Lynch Syndrome Database. Genet Med 2020;22:15-25.',
      <>
        SEER*Explorer: An interactive website for SEER cancer statistics [Internet]. Surveillance
        Research Program, National Cancer Institute. [Cited 2022 July 17]. Available from{' '}
        <ExternalLink to="https://seer.cancer.gov/statistics-network/explorer/">
          https://seer.cancer.gov/statistics-network/explorer/
        </ExternalLink>
        .
      </>,
      <>
        National Comprehensive Cancer Network. Genetic/Familial High-Risk Assessment: Colorectal.
        NCCN Guidelines Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published June 2022.
      </>,
      <>
        National Comprehensive Cancer Network. Genetic/Familial High-Risk Assessment: Breast,
        Ovarian, and Pancreatic. NCCN Guidelines Version 2.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published March 2022.
      </>,
      <>
        National Comprehensive Cancer Network. Prostate Cancer Early Detection. NCCN Guidelines
        Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published February
        2022.
      </>,
      'Goggins M, Overbeek KA, Brand R, et al. Management of patients with increased risk for familial pancreatic cancer: updated recommendations from the International Cancer of the Pancreas Screening (CAPS) Consortium. Gut. 2019;69(1):7-17.',
    ],
  },
  {
    gene: 'MUTYH',
    refrences: [
      'Win AK, Dowty JG, Cleary SP, et al. Risk of colorectal cancer for carriers of mutations in MUTYH, with and without a family history of cancer. Gastroenterology 2014;146:12081211.',
      'Nielsen M, Poley J, Verhoef S, et al. Duodenal carcinoma in MUTYH-associated polyposis J Clin Path 2006;59:1212-1215.',
      'Walton SJ, Kallenberg F, Clark S, et al. Frequency and features of duodenal adenomas in patients with MUTYH-associated polyposis. Clin Gastroenterol Hepatol 2016;14:986-992.',
      'Vogt S, Jones N, Christian D, et al. Expanded extracolonic tumor spectrum in MUTYHassociated polyposis. Gastroenterology 2009;137:1976-1985.',
      <>
        SEER*Explorer: An interactive website for SEER cancer statistics [Internet]. Surveillance
        Research Program, National Cancer Institute. [Cited 2022 July 17]. Available from{' '}
        <ExternalLink to="https://seer.cancer.gov/statistics-network/explorer/">
          https://seer.cancer.gov/statistics-network/explorer/
        </ExternalLink>
        .
      </>,
      <>
        National Comprehensive Cancer Network. Genetic/Familial High-Risk Assessment: Colorectal.
        NCCN Guidelines Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published June 2022.
      </>,
    ],
  },
  {
    gene: 'STK11',
    refrences: [
      'Giardiello FM, Brensinger JD, Tersmette AC, et al. Very High-Risk of cancer in familial Peutz-Jeghers syndrome. Gastroenterology. 2000;119(6):1447-53.',
      'Hearle N, Schumacher V, Menko FH, et al. Frequency and spectrum of cancers in the Peutz-Jeghers syndrome. Clin Cancer Res. 2006;12(10):3209-15.',
      'Ishida H, Tajima Y, Gonda T, Kumamoto K, Ishibashi K, Iwama T. Update on our investigation of malignant tumors associated with Peutz-Jeghers syndrome in Japan. Surg Today. 2016;46(11):1231-1242.',
      'Grant RC, Selander I, Connor AA, et al. Prevalence of germline mutations in cancer predisposition genes in patients with pancreatic cancer. Gastroenterology 2015;148:556-564.',
      'Hu C, Hart SN, Polley EC, et al. Association between inherited germline mutations in cancer predisposition genes and risk of pancreatic cancer. JAMA 2018;319:2401-2409.',
      'Lowery MA, Wong W, Jordan EJ, et al. Prospective evaluation of germline alterations in patients with exocrine pancreatic neoplasms. J Natl Cancer Inst 2018;110:1067-1074.',
      'Rainone M, Singh I, Salo-Mullen EE, et al. An emerging paradigm for germline testing in pancreatic ductal adenocarcinoma and immediate implications for clinical practice: a review. JAMA Oncol 2020;6:764-771.',
      "Salo-Mullen EE, O'Reilly EM, Kelsen DP, et al. Identification of germline genetic mutations in patients with pancreatic cancer. Cancer 2015;121:4382-4388.",
      'Shindo K, Yu J, Suenaga M, et al. Deleterious germline mutations in patients with apparently sporadic pancreatic adenocarcinoma. J Clin Oncol 2017;35:3382-3390.',
      <>
        SEER*Explorer: An interactive website for SEER cancer statistics [Internet]. Surveillance
        Research Program, National Cancer Institute. [Cited 2022 July 17]. Available from{' '}
        <ExternalLink to="https://seer.cancer.gov/statistics-network/explorer/">
          https://seer.cancer.gov/statistics-network/explorer/
        </ExternalLink>
        .
      </>,
      <>
        National Comprehensive Cancer Network. Genetic/Familial High-Risk Assessment: Colorectal.
        NCCN Guidelines Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published June 2022.
      </>,
      <>
        National Comprehensive Cancer Network. Genetic/Familial High-Risk Assessment: Breast,
        Ovarian, and Pancreatic. NCCN Guidelines Version 2.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published March 2022.
      </>,
      'Goggins M, Overbeek KA, Brand R, et al. Management of patients with increased risk for familial pancreatic cancer: updated recommendations from the International Cancer of the Pancreas Screening (CAPS) Consortium. Gut. 2019;69(1):7-17.',
    ],
  },
  {
    gene: 'SMAD4',
    refrences: [
      'Syngal S, Brand R, Church J, et al. ACG clinical guideline: Genetic testing and management of hereditary gastrointestinal cancer syndromes. Am J Gastroenterol 2015;110:223-262.',
      <>
        SEER*Explorer: An interactive website for SEER cancer statistics [Internet]. Surveillance
        Research Program, National Cancer Institute. [Cited 2022 July 17]. Available from{' '}
        <ExternalLink to="https://seer.cancer.gov/statistics-network/explorer/">
          https://seer.cancer.gov/statistics-network/explorer/
        </ExternalLink>
        .
      </>,
      <>
        National Comprehensive Cancer Network. Genetic/Familial High-Risk Assessment: Colorectal.
        NCCN Guidelines Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published June 2022.
      </>,
    ],
  },
  {
    gene: 'BMPR1A',
    refrences: [
      'Syngal S, Brand R, Church J, et al. ACG clinical guideline: Genetic testing and management of hereditary gastrointestinal cancer syndromes. Am J Gastroenterol 2015;110:223-262.',
      'Latchford AR, Neale K, Phillips RKS, Clark SK. Juvenile polyposis syndrome: a study of genotype, phenotype, and long-term outcome. Dis Colon Rectum. 2012;55(10):1038-1043.',
      'Aytac E, Sulu B, Heald B, et al. Genotype-defined cancer risk in juvenile polyposis syndrome. Br J Surg. 2015;102(1):114-118.',
      <>
        SEER*Explorer: An interactive website for SEER cancer statistics [Internet]. Surveillance
        Research Program, National Cancer Institute. [Cited 2022 July 17]. Available from{' '}
        <ExternalLink to="https://seer.cancer.gov/statistics-network/explorer/">
          https://seer.cancer.gov/statistics-network/explorer/
        </ExternalLink>
      </>,
      <>
        National Comprehensive Cancer Network. Genetic/Familial High-Risk Assessment: Colorectal.
        NCCN Guidelines Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published June 2022.
      </>,
    ],
  },
  {
    gene: 'LDLR',
    refrences: [
      <>
        Familial Hypercholesterolemia Treatments - The FH Foundation. Accessed July 24, 2022.
        Available at{' '}
        <ExternalLink to="https://thefhfoundation.org/fh-treatments">
          https://thefhfoundation.org/fh-treatments
        </ExternalLink>{' '}
        .
      </>,
      'Gidding SS, Champagne MA, de Ferranti SD, Defesche J, Ito MK, Knowles JW, McCrindle B, Raal F, Rader D, Santos RD, Lopes-Virella M, Watts GF, Wierzbicki AS, et al. The agenda for familial hypercholesterolemia: a scientific statement from the American Heart Association. Circulation. 2015;132:2167–92.',
      <>
        Ison HE, Clarke SL, Knowles JW. Familial Hypercholesterolemia. 2014 Jan 2 [Updated 2022 Jul
        7]. In: Adam MP, Mirzaa GM, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle
        (WA): University of Washington, Seattle; 1993-2022. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK174884/">
          https://www.ncbi.nlm.nih.gov/books/NBK174884/
        </ExternalLink>{' '}
      </>,
      'Watts GF, Gidding S, Wierzbicki AS, et al. Integrated guidance on the care of familial hypercholesterolaemia from the International FH Foundation. Int J Cardiol. 2014;171(3):309-25.',
    ],
  },
  {
    gene: 'FBN1',
    refrences: [
      <>
        Dietz H. FBN1-Related Marfan Syndrome. 2001 Apr 18 [Updated 2022 Feb 17]. In: Adam MP,
        Mirzaa GM, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA): University of
        Washington, Seattle; 1993-2022. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1335/">
          https://www.ncbi.nlm.nih.gov/books/NBK1335/
        </ExternalLink>
      </>,
      'Hiratzka LF, Bakris GL, Beckman JA, et al. 2010 ACCF/AHA/AATS/ACR/ASA/SCA/SCAI/SIR/STS/SVM guidelines for the diagnosis and management of patients with Thoracic Aortic Disease: a report of the American College of Cardiology Foundation/American Heart Association Task Force on Practice Guidelines, American Association for Thoracic Surgery, American College of Radiology, American Stroke Association, Society of Cardiovascular Anesthesiologists, Society for Cardiovascular Angiography and Interventions, Society of Interventional Radiology, Society of Thoracic Surgeons, and Society for Vascular Medicine. Circulation. 2010;13(121):e266-369.',
    ],
  },
  {
    gene: 'MYBPC3',
    refrences: [
      <>
        Cirino AL, Ho C. Hypertrophic Cardiomyopathy Overview. 2008 Aug 5 [Updated 2021 Jul 8]. In:
        Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA):
        University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1768/">
          https://www.ncbi.nlm.nih.gov/books/NBK1768/
        </ExternalLink>
      </>,
      'Hershberger RE, Lindenfeld J, Mestroni L, et al. Genetic evaluation of cardiomyopathy--a Heart Failure Society of America practice guideline. J Card Fail. 2009;2(15):83-97.',
      'Ommen, S. R., Mital, S., Burke, M. A., Day, S. M., Deswal, A., Elliott, P., Evanovich, L. L., Hung, J., Joglar, J. A., Kantor, P., Kimmelstiel, C., Kittleson, M., Link, M. S., Maron, M. S., Martinez, M. W., Miyake, C. Y., Schaff, H. V., Semsarian, C., & Sorajja, P. (2020). 2020 AHA/ACC Guideline for the Diagnosis and Treatment of Patients With Hypertrophic Cardiomyopathy: A Report of the American College of Cardiology/American Heart Association Joint Committee on Clinical Practice Guidelines. Circulation, 142(25), e558–e631.',
    ],
  },
  {
    gene: 'TSC2',
    refrences: [
      'Borkowska J, Schwartz RA, Kotulska K, Jozwiak S. Tuberous sclerosis complex: tumors and tumorigenesis. Int J Dermatol. 2011;50(1):13-20.',
      'Krueger DA, Northrup H. Tuberous sclerosis complex surveillance and management: recommendations of the 2012 International Tuberous Sclerosis Complex Consensus Conference. Pediatr Neurol. 2013;49(4):255-65.',
      <>
        Northrup H, Koenig MK, Pearson DA, et al. Tuberous Sclerosis Complex. 1999 Jul 13 [Updated
        2021 Dec 9]. In: Adam MP, Mirzaa GM, Pagon RA, et al., editors. GeneReviews® [Internet].
        Seattle (WA): University of Washington, Seattle; 1993-2022. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1220/">
          https://www.ncbi.nlm.nih.gov/books/NBK1220/
        </ExternalLink>
      </>,
      'Randle SC. Tuberous Sclerosis Complex: A Review. Pediatr Ann. 2017;46(4):e166-e171',
      <>
        Treatment Guidelines. TSC Alliance.{' '}
        <ExternalLink to="https://www.tsalliance.org/individuals-families/treatment-guidelines/">
          https://www.tsalliance.org/individuals-families/treatment-guidelines/
        </ExternalLink>
        . Accessed July 25, 2022.
      </>,
    ],
  },
  {
    gene: 'COL3A1',
    refrences: [
      'Byers PH, Belmont J, Black J, et al. Diagnosis, natural history, and management in vascular Ehlers-Danlos syndrome. Am J Med Genet C Semin Med Genet. 2017;175(1):40-47.',
      <>
        Byers PH. Vascular Ehlers-Danlos Syndrome. 1999 Sep 2 [Updated 2019 Feb 21]. In: Adam MP,
        Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA): University
        of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1494/">
          https://www.ncbi.nlm.nih.gov/books/NBK1494/
        </ExternalLink>
      </>,
      'Malfait F, De paepe A. The Ehlers-Danlos syndrome. Adv Exp Med Biol. 2014;802:129-43.',
    ],
  },
  {
    gene: 'RYR1',
    refrences: [
      <>
        ACMG ACT Sheets and Algorithms. Bethesda (MD): American College of Medical Genetics and
        Genomics. Available from:{' '}
        <ExternalLink to="https://www.acmg.net/PDFLibrary/Malignant-Hyperthermia.pdf">
          https://www.acmg.net/PDFLibrary/Malignant-Hyperthermia.pdf
        </ExternalLink>
        . Accessed August 19, 2022.
      </>,
      <>
        Introduction to MH Testing - MHAUS. Available from:{' '}
        <ExternalLink to="https://www.mhaus.org/testing/introduction-to-mh-testing/">
          https://www.mhaus.org/testing/introduction-to-mh-testing/
        </ExternalLink>
        . Accessed August 19, 2022.
      </>,
      <>
        Home - MHAUS. Available from:{' '}
        <ExternalLink to="https://www.mhaus.org/">https://www.mhaus.org/</ExternalLink>. Accessed
        August 19 2022.
      </>,
      <>
        Rosenberg H, Sambuughin N, Riazi S, et al. Malignant Hyperthermia Susceptibility. 2003 Dec
        19 [Updated 2020 Jan 16]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews®
        [Internet]. Seattle (WA): University of Washington, Seattle; 1993-2020. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1146/">
          https://www.ncbi.nlm.nih.gov/books/NBK1146/
        </ExternalLink>
      </>,
    ],
  },
  {
    gene: 'KCNH2',
    refrences: [
      <>
        Alders M, Bikker H, Christiaans I. Long QT Syndrome. 2003 Feb 20 [Updated 2018 Feb 8]. In:
        Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA):
        University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1129/">
          https://www.ncbi.nlm.nih.gov/books/NBK1129/
        </ExternalLink>
      </>,
      'Priori SG, Wilde AA, Horie M, et al. HRS/EHRA/APHRS expert consensus statement on the diagnosis and management of patients with inherited primary arrhythmia syndromes: document endorsed by HRS, EHRA, and APHRS in May 2013 and by ACCF, AHA, PACES, and AEPC in June 2013. Heart Rhythm. 2013;12(10):1932-63.',
    ],
  },
  {
    gene: 'MYH7_HCM',
    refrences: [
      <>
        Cirino AL, Ho C. Hypertrophic Cardiomyopathy Overview. 2008 Aug 5 [Updated 2021 Jul 8]. In:
        Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA):
        University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1768/">
          https://www.ncbi.nlm.nih.gov/books/NBK1768/
        </ExternalLink>
      </>,
      'Hershberger RE, Lindenfeld J, Mestroni L, et al. Genetic evaluation of cardiomyopathy--a Heart Failure Society of America practice guideline. J Card Fail. 2009;2(15):83-97.',
      'Ommen, S. R., Mital, S., Burke, M. A., Day, S. M., Deswal, A., Elliott, P., Evanovich, L. L., Hung, J., Joglar, J. A., Kantor, P., Kimmelstiel, C., Kittleson, M., Link, M. S., Maron, M. S., Martinez, M. W., Miyake, C. Y., Schaff, H. V., Semsarian, C., & Sorajja, P. (2020). 2020 AHA/ACC Guideline for the Diagnosis and Treatment of Patients With Hypertrophic Cardiomyopathy: A Report of the American College of Cardiology/American Heart Association Joint Committee on Clinical Practice Guidelines. Circulation, 142(25), e558–e631.',
    ],
  },
  {
    gene: 'MYH7_DCM',
    refrences: [
      'Hershberger RE, Givertz MM, Ho CY, et al. Genetic evaluation of cardiomyopathy: a clinical practice resource of the American College of Medical Genetics and Genomics (ACMG) [published correction appears in Genet Med. 2019 Oct;21(10):2406-2409]. Genet Med. 2018;20(9):899-909.',
      <>
        Hershberger RE, Jordan E. Dilated Cardiomyopathy Overview. 2007 Jul 27 [Updated 2022 Apr 7].
        In: Adam MP, Everman DB, Mirzaa GM, et al., editors. GeneReviews® [Internet]. Seattle (WA):
        University of Washington, Seattle; 1993-2022. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1309/">
          https://www.ncbi.nlm.nih.gov/books/NBK1309/
        </ExternalLink>
      </>,
      'Hershberger RE, Lindenfeld J, Mestroni L, et al. Genetic evaluation of cardiomyopathy--a Heart Failure Society of America practice guideline. J Card Fail. 2009;2(15):83-97.',
    ],
  },
  {
    gene: 'GLA',
    refrences: [
      'Laney DA, Bennett RL, Clarke V, et al. Fabry disease practice guidelines: recommendations of the National Society of Genetic Counselors. J Genet Couns. 2013;22(5):555-564. doi:10.1007/s10897-013-9613-3',
      <>
        Mehta A, Hughes DA. Fabry Disease. 2002 Aug 5 [Updated 2022 Jan 27]. In: Adam MP, Everman
        DB, Mirzaa GM, et al., editors. GeneReviews® [Internet]. Seattle (WA): University of
        Washington, Seattle; 1993-2022. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1292/">
          https://www.ncbi.nlm.nih.gov/books/NBK1292/
        </ExternalLink>
      </>,
      'Mehta AB, Beck M, Sunder-Plassmann G. Fabry Disease: Perspectives from 5 Years of FOS. Oxford: PharmaGenesis. 2006.',
    ],
  },
  {
    gene: 'ATP7B',
    refrences: [
      'European Association for Study of Liver. EASL Clinical Practice Guidelines: Wilson’s disease. J Hepatol. 2012;56(3):671-685.',
      'Patil M, Sheth KA, Krishnamurthy AC, Devarbhavi H. A Review and Current Perspective on Wilson Disease. Journal of Clinical and Experimental Hepatology. 2013;3(4):321-336. doi:10.1016/j.jceh.2013.06.002',
      'Socha P, Janczyk W, Dhawan A, et al. Wilson’s Disease in Children: A Position Paper by the Hepatology Committee of the European Society for Paediatric Gastroenterology, Hepatology and Nutrition. J Pediatr Gastroenterol Nutr. 2018;66(2):334-344.',
      'Roberts EA, Schilsky ML, American Association for Study of Liver Diseases (AASLD). Diagnosis and treatment of Wilson disease: an update. Hepatology. 2008;47(6):2089-2111.',
      <>
        Weiss KH. Wilson Disease. 1999 Oct 22 [Updated 2016 Jul 29]. In: Adam MP, Ardinger HH, Pagon
        RA, et al., editors. GeneReviews® [Internet]. Seattle (WA): University of Washington,
        Seattle; 1993-2020. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1512/">
          https://www.ncbi.nlm.nih.gov/books/NBK1512/
        </ExternalLink>
      </>,
    ],
  },
  {
    gene: 'KCNQ1',
    refrences: [
      <>
        Alders M, Bikker H, Christiaans I. Long QT Syndrome. 2003 Feb 20 [Updated 2018 Feb 8]. In:
        Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA):
        University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1129/">
          https://www.ncbi.nlm.nih.gov/books/NBK1129/
        </ExternalLink>
      </>,
      'Priori SG, Wilde AA, Horie M, et al. HRS/EHRA/APHRS expert consensus statement on the diagnosis and management of patients with inherited primary arrhythmia syndromes: document endorsed by HRS, EHRA, and APHRS in May 2013 and by ACCF, AHA, PACES, and AEPC in June 2013. Heart Rhythm. 2013;12(10):1932-63.',
    ],
  },
  {
    gene: 'LMNA',
    refrences: [
      <>
        Hershberger RE, Jordan E. LMNA-Related Dilated Cardiomyopathy. 2008 Jun 12 [Updated 2022 Mar
        17]. In: Adam MP, Everman DB, Mirzaa GM, et al., editors. GeneReviews® [Internet]. Seattle
        (WA): University of Washington, Seattle; 1993-2022. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1674/">
          https://www.ncbi.nlm.nih.gov/books/NBK1674/
        </ExternalLink>
      </>,
      'Hershberger RE, Givertz MM, Ho CY, et al. Genetic evaluation of cardiomyopathy: a clinical practice resource of the American College of Medical Genetics and Genomics (ACMG) [published correction appears in Genet Med. 2019 Oct;21(10):2406-2409]. Genet Med. 2018;20(9):899-909.',
      'Hershberger RE, Lindenfeld J, Mestroni L, et al. Genetic evaluation of cardiomyopathy--a Heart Failure Society of America practice guideline. J Card Fail. 2009;2(15):83-97.',
    ],
  },
  {
    gene: 'RB1',
    refrences: [
      'Fletcher O, Easton D, Anderson K, Gilham C, Jay M, Peto J. Lifetime risks of common cancers among retinoblastoma survivors. J Natl Cancer Inst. 2004;96(5):357-63.',
      'Kamihara J, Bourdeaut F, Foulkes WD, et al. Retinoblastoma and Neuroblastoma Predisposition and Surveillance. Clin Cancer Res. 2017;23(13):e98-e106.',
      'Skalet AH, Gombos DS, Gallie BL, et al. Screening Children at Risk for Retinoblastoma: Consensus Report from the American Association of Ophthalmic Oncologists and Pathologists. Ophthalmology. 2018;125(3):453-458.',
      'Temming P, Viehmann A, Arendt M, et al. Pediatric second primary malignancies after retinoblastoma treatment. Pediatr Blood Cancer. 2015;62(10):1799-804.',
    ],
  },
  {
    gene: 'MEN1',
    refrences: [
      <>
        Giusti F, Marini F, Brandi ML. Multiple Endocrine Neoplasia Type 1. 2005 Aug 31 [Updated
        2022 Mar 10]. In: Adam MP, Everman DB, Mirzaa GM, et al., editors. GeneReviews® [Internet].
        Seattle (WA): University of Washington, Seattle; 1993-2022. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1538/">
          https://www.ncbi.nlm.nih.gov/books/NBK1538/
        </ExternalLink>
      </>,
      'Mcdonnell JE, Gild ML, Clifton-bligh RJ, Robinson BG. Multiple endocrine neoplasia: an update. Intern Med J. 2019;49(8):954-961.',
      'Thakker RV, Newey PJ, Walls GV, et al. Clinical practice guidelines for multiple endocrine neoplasia type 1 (MEN1). J Clin Endocrinol Metab. 2012;97(9):2990-3011.',
      <>
        National Comprehensive Cancer Network. Neuroendocrine and Adrenal Tumors. NCCN Guidelines
        Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published May 2022.
      </>,
    ],
  },
  {
    gene: 'SCN5A_LQTS',
    refrences: [
      <>
        Alders M, Bikker H, Christiaans I. Long QT Syndrome. 2003 Feb 20 [Updated 2018 Feb 8]. In:
        Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA):
        University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1129/">
          https://www.ncbi.nlm.nih.gov/books/NBK1129/
        </ExternalLink>
      </>,
      <>
        Brugada R, Campuzano O, Sarquella-Brugada G, et al. Brugada Syndrome. 2005 Mar 31 [Updated
        2016 Nov 17]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet].
        Seattle (WA): University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1517/">
          https://www.ncbi.nlm.nih.gov/books/NBK1517/
        </ExternalLink>
      </>,
      'Priori SG, Wilde AA, Horie M, et al. HRS/EHRA/APHRS expert consensus statement on the diagnosis and management of patients with inherited primary arrhythmia syndromes: document endorsed by HRS, EHRA, and APHRS in May 2013 and by ACCF, AHA, PACES, and AEPC in June 2013. Heart Rhythm. 2013;12(10):1932-63.',
    ],
  },
  {
    gene: 'SCN5A_BRS',
    refrences: [
      <>
        Alders M, Bikker H, Christiaans I. Long QT Syndrome. 2003 Feb 20 [Updated 2018 Feb 8]. In:
        Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA):
        University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1129/">
          https://www.ncbi.nlm.nih.gov/books/NBK1129/
        </ExternalLink>
      </>,
      <>
        Brugada R, Campuzano O, Sarquella-Brugada G, et al. Brugada Syndrome. 2005 Mar 31 [Updated
        2016 Nov 17]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet].
        Seattle (WA): University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1517/">
          https://www.ncbi.nlm.nih.gov/books/NBK1517/
        </ExternalLink>
      </>,
      'Priori SG, Wilde AA, Horie M, et al. HRS/EHRA/APHRS expert consensus statement on the diagnosis and management of patients with inherited primary arrhythmia syndromes: document endorsed by HRS, EHRA, and APHRS in May 2013 and by ACCF, AHA, PACES, and AEPC in June 2013. Heart Rhythm. 2013;12(10):1932-63.',
    ],
  },
  {
    gene: 'OTC',
    refrences: [
      'Brassier A, Gobin S, Arnoux JB, et al. Long-term outcomes in Ornithine Transcarbamylase deficiency: a series of 90 patients. Orphanet J Rare Dis. 2015;10:58. Published 2015 May 10. doi:10.1186/s13023-015-0266-1',
      <>
        Ah Mew N, Simpson KL, Gropman AL, et al. Urea Cycle Disorders Overview. 2003 Apr 29 [Updated
        2017 Jun 22]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet].
        Seattle (WA): University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1217/">
          https://www.ncbi.nlm.nih.gov/books/NBK1217/
        </ExternalLink>
      </>,
      <>
        Lichter-Konecki U, Caldovic L, Morizono H, et al. Ornithine Transcarbamylase Deficiency.
        2013 Aug 29 [Updated 2022 May 26]. In: Adam MP, Everman DB, Mirzaa GM, et al., editors.
        GeneReviews® [Internet]. Seattle (WA): University of Washington, Seattle; 1993-2022.
        Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK154378/">
          https://www.ncbi.nlm.nih.gov/books/NBK154378/
        </ExternalLink>
      </>,
    ],
  },
  {
    gene: 'DSP',
    refrences: [
      'Hershberger RE, Givertz MM, Ho CY, et al. Genetic evaluation of cardiomyopathy: a clinical practice resource of the American College of Medical Genetics and Genomics (ACMG) [published correction appears in Genet Med. 2019 Oct;21(10):2406-2409]. Genet Med. 2018;20(9):899-909.',
      <>
        Hershberger RE, Jordan E. Dilated Cardiomyopathy Overview. 2007 Jul 27 [Updated 2022 Apr 7].
        In: Adam MP, Everman DB, Mirzaa GM, et al., editors. GeneReviews® [Internet]. Seattle (WA):
        University of Washington, Seattle; 1993-2022. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1309/">
          https://www.ncbi.nlm.nih.gov/books/NBK1309/
        </ExternalLink>
      </>,
      'Hershberger RE, Lindenfeld J, Mestroni L, et al. Genetic evaluation of cardiomyopathy--a Heart Failure Society of America practice guideline. J Card Fail. 2009;2(15):83-97.',
      <>
        McNally E, MacLeod H, Dellefave-Castillo L. Arrhythmogenic Right Ventricular Cardiomyopathy.
        2005 Apr 18 [Updated 2017 May 25]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors.
        GeneReviews® [Internet]. Seattle (WA): University of Washington, Seattle; 1993-2020.
        Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1131/">
          https://www.ncbi.nlm.nih.gov/books/NBK1131/
        </ExternalLink>
      </>,
      'Towbin JA, McKenna WJ, Abrams DJ, et al. 2019 HRS expert consensus statement on evaluation, risk stratification, and management of arrhythmogenic cardiomyopathy. Heart Rhythm. 2019;16(11):e301-e372.',
    ],
  },
  {
    gene: 'TSC1',
    refrences: [
      'Borkowska J, Schwartz RA, Kotulska K, Jozwiak S. Tuberous sclerosis complex: tumors and tumorigenesis. Int J Dermatol. 2011;50(1):13-20.',
      'Krueger DA, Northrup H. Tuberous sclerosis complex surveillance and management: recommendations of the 2012 International Tuberous Sclerosis Complex Consensus Conference. Pediatr Neurol. 2013;49(4):255-65.',
      <>
        Northrup H, Koenig MK, Pearson DA, et al. Tuberous Sclerosis Complex. 1999 Jul 13 [Updated
        2021 Dec 9]. In: Adam MP, Everman DB, Mirzaa GM, et al., editors. GeneReviews® [Internet].
        Seattle (WA): University of Washington, Seattle; 1993-2022. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1220/">
          https://www.ncbi.nlm.nih.gov/books/NBK1220/
        </ExternalLink>
      </>,
      'Randle SC. Tuberous Sclerosis Complex: A Review. Pediatr Ann. 2017;46(4):e166-e171',
      <>
        Treatment Guidelines - TS Alliance. TS Alliance.{' '}
        <ExternalLink to="https://www.tsalliance.org/individuals-families/treatment-guidelines/">
          https://www.tsalliance.org/individuals-families/treatment-guidelines/
        </ExternalLink>
        . Accessed August 16, 2022.
      </>,
    ],
  },
  {
    gene: 'PKP2',
    refrences: [
      'Hershberger RE, Lindenfeld J, Mestroni L, et al. Genetic evaluation of cardiomyopathy--a Heart Failure Society of America practice guideline. J Card Fail. 2009;2(15):83-97.',
      <>
        McNally E, MacLeod H, Dellefave-Castillo L. Arrhythmogenic Right Ventricular Cardiomyopathy.
        2005 Apr 18 [Updated 2017 May 25]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors.
        GeneReviews® [Internet]. Seattle (WA): University of Washington, Seattle; 1993-2020.
        Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1131/">
          https://www.ncbi.nlm.nih.gov/books/NBK1131/
        </ExternalLink>
      </>,
      'Towbin JA, McKenna WJ, Abrams DJ, et al. 2019 HRS expert consensus statement on evaluation, risk stratification, and management of arrhythmogenic cardiomyopathy. Heart Rhythm. 2019;16(11):e301-e372.',
    ],
  },
  {
    gene: 'SDHB',
    refrences: [
      <>
        Else T, Greenberg S, Fishbein L. Hereditary Paraganglioma-Pheochromocytoma Syndromes. 2008
        May 21 [Updated 2018 Oct 4]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors.
        GeneReviews® [Internet]. Seattle (WA): University of Washington, Seattle; 1993-2020.
        Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1548/">
          https://www.ncbi.nlm.nih.gov/books/NBK1548/
        </ExternalLink>
      </>,
      'Fishbein L, Nathanson KL. Pheochromocytoma and paraganglioma: understanding the complexities of the genetic background. Cancer Genet. 2012;205(1-2):1-11.',
      'Muth A, Crona J, Gimm O, et al. Genetic testing and surveillance guidelines in hereditary pheochromocytoma and paraganglioma. J Intern Med. 2019;285(2):187-204.',
      'Rednam SP, Erez A, Druker H, et al. Von Hippel-Lindau and Hereditary Pheochromocytoma/Paraganglioma Syndromes: Clinical Features, Genetics, and Surveillance Recommendations in Childhood. Clin Cancer Res. 2017;23(12):e68-e75.',
      <>
        National Comprehensive Cancer Network. Neuroendocrine and Adrenal Tumors. NCCN Guidelines
        Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published May 2022.
      </>,
    ],
  },
  {
    gene: 'RYR2',
    refrences: [
      <>
        Napolitano C, Mazzanti A, Bloise R, et al. Catecholaminergic Polymorphic Ventricular
        Tachycardia. 2004 Oct 14 [Updated 2022 Jun 23]. In: Adam MP, Everman DB, Mirzaa GM, et al.,
        editors. GeneReviews® [Internet]. Seattle (WA): University of Washington, Seattle;
        1993-2022. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1289/">
          https://www.ncbi.nlm.nih.gov/books/NBK1289/
        </ExternalLink>
      </>,
      'Priori SG, Wilde AA, Horie M, et al. HRS/EHRA/APHRS expert consensus statement on the diagnosis and management of patients with inherited primary arrhythmia syndromes: document endorsed by HRS, EHRA, and APHRS in May 2013 and by ACCF, AHA, PACES, and AEPC in June 2013. Heart Rhythm. 2013;12(10):1932-63.',
    ],
  },
  {
    gene: 'VHL',
    refrences: [
      <>
        Active Surveillance. (2015, September 30). Accessed November 9, 2021. Available at{' '}
        <ExternalLink to="https://www.vhl.org/patients/clinical-care/screening/">
          https://www.vhl.org/patients/clinical-care/screening/
        </ExternalLink>
      </>,
      'Maher ER, Neumann HP, Richard S. von Hippel-Lindau disease: a clinical and scientific review. Eur J Hum Genet. 2011;19(6):617-23.',
      <>
        van Leeuwaarde RS, Ahmad S, Links TP, et al. Von Hippel-Lindau Syndrome. 2000 May 17
        [Updated 2018 Sep 6]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews®
        [Internet]. Seattle (WA): University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1463/">
          https://www.ncbi.nlm.nih.gov/books/NBK1463/
        </ExternalLink>
      </>,
      'Varshney N, Kebede AA, Owusu-dapaah H, Lather J, Kaushik M, Bhullar JS. A Review of Von Hippel-Lindau Syndrome. J Kidney Cancer VHL. 2017;4(3):20-29.',
      <>
        National Comprehensive Cancer Network. Neuroendocrine and Adrenal Tumors. NCCN Guidelines
        Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published May 2022.
      </>,
    ],
  },
  {
    gene: 'NF2',
    refrences: [
      'Asthagiri AR, Parry DM, Butman JA, et al. Neurofibromatosis type 2. Lancet. 2009; 373(9679):1974-86.',
      <>
        Evans DG. Neurofibromatosis 2. 1998 Oct 14 [Updated 2018 Mar 15]. In: Adam MP, Ardinger HH,
        Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA): University of Washington,
        Seattle; 1993-2020. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1201/">
          https://www.ncbi.nlm.nih.gov/books/NBK1201/
        </ExternalLink>
      </>,
      'Evans DG, Raymond FL, Barwell JG, Halliday D. Genetic testing and screening of individuals at risk of NF2. Clin Genet. 2012;82(5):416-24.',
    ],
  },
  {
    gene: 'RET',
    refrences: [
      <>
        Eng C. Multiple Endocrine Neoplasia Type 2. 1999 Sep 27 [Updated 2019 Aug 15]. In: Adam MP,
        Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA): University
        of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1257/">
          https://www.ncbi.nlm.nih.gov/books/NBK1257/
        </ExternalLink>
      </>,
      'Mcdonnell JE, Gild ML, Clifton-bligh RJ, Robinson BG. Multiple endocrine neoplasia: an update. Intern Med J. 2019;49(8):954-961.',
      "Schuffenecker I, Ginet N, Goldgar D, et al. Prevalence and parental origin of de novo RET mutations in multiple endocrine neoplasia type 2A and familial medullary thyroid carcinoma. Le Groupe d'Etude des Tumeurs a Calcitonine. Am J Hum Genet. 1997;60(1):233-7.",
      'Wells SA, Asa SL, Dralle H, et al. Revised American Thyroid Association guidelines for the management of medullary thyroid carcinoma. Thyroid. 2015;25(6):567-610.',
      <>
        National Comprehensive Cancer Network. Neuroendocrine and Adrenal Tumors. NCCN Guidelines
        Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published May 2022.
      </>,
    ],
  },
  {
    gene: 'TNNT2_DCM',
    refrences: [
      'Hershberger RE, Givertz MM, Ho CY, et al. Genetic evaluation of cardiomyopathy: a clinical practice resource of the American College of Medical Genetics and Genomics (ACMG) [published correction appears in Genet Med. 2019 Oct;21(10):2406-2409]. Genet Med. 2018;20(9):899-909.',
      <>
        Hershberger RE, Jordan E. Dilated Cardiomyopathy Overview. 2007 Jul 27 [Updated 2022 Apr 7].
        In: Adam MP, Everman DB, Mirzaa GM, et al., editors. GeneReviews® [Internet]. Seattle (WA):
        University of Washington, Seattle; 1993-2022. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1309/">
          https://www.ncbi.nlm.nih.gov/books/NBK1309/
        </ExternalLink>
      </>,
      'Hershberger RE, Lindenfeld J, Mestroni L, et al. Genetic evaluation of cardiomyopathy--a Heart Failure Society of America practice guideline. J Card Fail. 2009;2(15):83-97.',
    ],
  },
  {
    gene: 'TNNT2_HCM',
    refrences: [
      <>
        Cirino AL, Ho C. Hypertrophic Cardiomyopathy Overview. 2008 Aug 5 [Updated 2021 Jul 8]. In:
        Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA):
        University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1768/">
          https://www.ncbi.nlm.nih.gov/books/NBK1768/
        </ExternalLink>
      </>,
      'Hershberger RE, Lindenfeld J, Mestroni L, et al. Genetic evaluation of cardiomyopathy--a Heart Failure Society of America practice guideline. J Card Fail. 2009;2(15):83-97.',
      'Ommen, S. R., Mital, S., Burke, M. A., Day, S. M., Deswal, A., Elliott, P., Evanovich, L. L., Hung, J., Joglar, J. A., Kantor, P., Kimmelstiel, C., Kittleson, M., Link, M. S., Maron, M. S., Martinez, M. W., Miyake, C. Y., Schaff, H. V., Semsarian, C., & Sorajja, P. (2020). 2020 AHA/ACC Guideline for the Diagnosis and Treatment of Patients With Hypertrophic Cardiomyopathy: A Report of the American College of Cardiology/American Heart Association Joint Committee on Clinical Practice Guidelines. Circulation, 142(25), e558–e631.',
    ],
  },
  {
    gene: 'SMAD3',
    refrences: [
      'Hiratzka LF, Bakris GL, Beckman JA, et al. 2010 ACCF/AHA/AATS/ACR/ASA/SCA/SCAI/SIR/STS/SVM guidelines for the diagnosis and management of patients with Thoracic Aortic Disease: a report of the American College of Cardiology Foundation/American Heart Association Task Force on Practice Guidelines, American Association for Thoracic Surgery, American College of Radiology, American Stroke Association, Society of Cardiovascular Anesthesiologists, Society for Cardiovascular Angiography and Interventions, Society of Interventional Radiology, Society of Thoracic Surgeons, and Society for Vascular Medicine. Circulation. 2010;13(121):e266-369.',
      <>
        Loeys BL, Dietz HC. Loeys-Dietz Syndrome. 2008 Feb 28 [Updated 2018 Mar 1]. In: Adam MP,
        Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA): University
        of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1133/">
          https://www.ncbi.nlm.nih.gov/books/NBK1133/
        </ExternalLink>
      </>,
      <>
        Milewicz DM, Regalado E. Heritable Thoracic Aortic Disease Overview. 2003 Feb 13 [Updated
        2017 Dec 14]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet].
        Seattle (WA): University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1120/">
          https://www.ncbi.nlm.nih.gov/books/NBK1120/
        </ExternalLink>
      </>,
    ],
  },
  {
    gene: 'DSG2',
    refrences: [
      'Hershberger RE, Lindenfeld J, Mestroni L, et al. Genetic evaluation of cardiomyopathy--a Heart Failure Society of America practice guideline. J Card Fail. 2009;2(15):83-97.',
      <>
        McNally E, MacLeod H, Dellefave-Castillo L. Arrhythmogenic Right Ventricular Cardiomyopathy.
        2005 Apr 18 [Updated 2017 May 25]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors.
        GeneReviews® [Internet]. Seattle (WA): University of Washington, Seattle; 1993-2020.
        Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1131/">
          https://www.ncbi.nlm.nih.gov/books/NBK1131/
        </ExternalLink>
      </>,
      'Towbin JA, McKenna WJ, Abrams DJ, et al. 2019 HRS expert consensus statement on evaluation, risk stratification, and management of arrhythmogenic cardiomyopathy. Heart Rhythm. 2019;16(11):e301-e372.',
    ],
  },
  {
    gene: 'SDHD',
    refrences: [
      <>
        Else T, Greenberg S, Fishbein L. Hereditary Paraganglioma-Pheochromocytoma Syndromes. 2008
        May 21 [Updated 2018 Oct 4]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors.
        GeneReviews® [Internet]. Seattle (WA): University of Washington, Seattle; 1993-2020.
        Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1548/">
          https://www.ncbi.nlm.nih.gov/books/NBK1548/
        </ExternalLink>
      </>,
      'Fishbein L, Nathanson KL. Pheochromocytoma and paraganglioma: understanding the complexities of the genetic background. Cancer Genet. 2012;205(1-2):1-11.',
      'Muth A, Crona J, Gimm O, et al. Genetic testing and surveillance guidelines in hereditary pheochromocytoma and paraganglioma. J Intern Med. 2019;285(2):187-204.',
      'Rednam SP, Erez A, Druker H, et al. Von Hippel-Lindau and Hereditary Pheochromocytoma/Paraganglioma Syndromes: Clinical Features, Genetics, and Surveillance Recommendations in Childhood. Clin Cancer Res. 2017;23(12):e68-e75.',
      <>
        National Comprehensive Cancer Network. Neuroendocrine and Adrenal Tumors. NCCN Guidelines
        Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published May 2022.
      </>,
    ],
  },
  {
    gene: 'TGFBR2',
    refrences: [
      'Hiratzka LF, Bakris GL, Beckman JA, et al. 2010 ACCF/AHA/AATS/ACR/ASA/SCA/SCAI/SIR/STS/SVM guidelines for the diagnosis and management of patients with Thoracic Aortic Disease: a report of the American College of Cardiology Foundation/American Heart Association Task Force on Practice Guidelines, American Association for Thoracic Surgery, American College of Radiology, American Stroke Association, Society of Cardiovascular Anesthesiologists, Society for Cardiovascular Angiography and Interventions, Society of Interventional Radiology, Society of Thoracic Surgeons, and Society for Vascular Medicine. Circulation. 2010;13(121):e266-369.',
      <>
        Loeys BL, Dietz HC. Loeys-Dietz Syndrome. 2008 Feb 28 [Updated 2018 Mar 1]. In: Adam MP,
        Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA): University
        of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1133/">
          https://www.ncbi.nlm.nih.gov/books/NBK1133/
        </ExternalLink>
      </>,
      <>
        Milewicz DM, Regalado E. Heritable Thoracic Aortic Disease Overview. 2003 Feb 13 [Updated
        2017 Dec 14]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet].
        Seattle (WA): University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1120/">
          https://www.ncbi.nlm.nih.gov/books/NBK1120/
        </ExternalLink>
      </>,
    ],
  },
  {
    gene: 'TNNI3',
    refrences: [
      <>
        Cirino AL, Ho C. Hypertrophic Cardiomyopathy Overview. 2008 Aug 5 [Updated 2021 Jul 8]. In:
        Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA):
        University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1768/">
          https://www.ncbi.nlm.nih.gov/books/NBK1768/
        </ExternalLink>
      </>,
      'Hershberger RE, Lindenfeld J, Mestroni L, et al. Genetic evaluation of cardiomyopathy--a Heart Failure Society of America practice guideline. J Card Fail. 2009;2(15):83-97.',
      'Ommen, S. R., Mital, S., Burke, M. A., Day, S. M., Deswal, A., Elliott, P., Evanovich, L. L., Hung, J., Joglar, J. A., Kantor, P., Kimmelstiel, C., Kittleson, M., Link, M. S., Maron, M. S., Martinez, M. W., Miyake, C. Y., Schaff, H. V., Semsarian, C., & Sorajja, P. (2020). 2020 AHA/ACC Guideline for the Diagnosis and Treatment of Patients With Hypertrophic Cardiomyopathy: A Report of the American College of Cardiology/American Heart Association Joint Committee on Clinical Practice Guidelines. Circulation, 142(25), e558–e631.',
    ],
  },
  {
    gene: 'TPM1',
    refrences: [
      <>
        Cirino AL, Ho C. Hypertrophic Cardiomyopathy Overview. 2008 Aug 5 [Updated 2021 Jul 8]. In:
        Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA):
        University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1768/">
          https://www.ncbi.nlm.nih.gov/books/NBK1768/
        </ExternalLink>
      </>,
      'Hershberger RE, Lindenfeld J, Mestroni L, et al. Genetic evaluation of cardiomyopathy--a Heart Failure Society of America practice guideline. J Card Fail. 2009;2(15):83-97.',
      'Ommen, S. R., Mital, S., Burke, M. A., Day, S. M., Deswal, A., Elliott, P., Evanovich, L. L., Hung, J., Joglar, J. A., Kantor, P., Kimmelstiel, C., Kittleson, M., Link, M. S., Maron, M. S., Martinez, M. W., Miyake, C. Y., Schaff, H. V., Semsarian, C., & Sorajja, P. (2020). 2020 AHA/ACC Guideline for the Diagnosis and Treatment of Patients With Hypertrophic Cardiomyopathy: A Report of the American College of Cardiology/American Heart Association Joint Committee on Clinical Practice Guidelines. Circulation, 142(25), e558–e631.',
    ],
  },
  {
    gene: 'DSC2',
    refrences: [
      'Hershberger RE, Lindenfeld J, Mestroni L, et al. Genetic evaluation of cardiomyopathy--a Heart Failure Society of America practice guideline. J Card Fail. 2009;2(15):83-97.',
      <>
        McNally E, MacLeod H, Dellefave-Castillo L. Arrhythmogenic Right Ventricular Cardiomyopathy.
        2005 Apr 18 [Updated 2017 May 25]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors.
        GeneReviews® [Internet]. Seattle (WA): University of Washington, Seattle; 1993-2020.
        Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1131/">
          https://www.ncbi.nlm.nih.gov/books/NBK1131/
        </ExternalLink>
      </>,
      'Towbin JA, McKenna WJ, Abrams DJ, et al. 2019 HRS expert consensus statement on evaluation, risk stratification, and management of arrhythmogenic cardiomyopathy. Heart Rhythm. 2019;16(11):e301-e372.',
    ],
  },
  {
    gene: 'SDHC',
    refrences: [
      <>
        Else T, Greenberg S, Fishbein L. Hereditary Paraganglioma-Pheochromocytoma Syndromes. 2008
        May 21 [Updated 2018 Oct 4]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors.
        GeneReviews® [Internet]. Seattle (WA): University of Washington, Seattle; 1993-2020.
        Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1548/">
          https://www.ncbi.nlm.nih.gov/books/NBK1548/
        </ExternalLink>
      </>,
      'Fishbein L, Nathanson KL. Pheochromocytoma and paraganglioma: understanding the complexities of the genetic background. Cancer Genet. 2012;205(1-2):1-11.',
      'Muth A, Crona J, Gimm O, et al. Genetic testing and surveillance guidelines in hereditary pheochromocytoma and paraganglioma. J Intern Med. 2019;285(2):187-204.',
      'Rednam SP, Erez A, Druker H, et al. Von Hippel-Lindau and Hereditary Pheochromocytoma/Paraganglioma Syndromes: Clinical Features, Genetics, and Surveillance Recommendations in Childhood. Clin Cancer Res. 2017;23(12):e68-e75',
      <>
        National Comprehensive Cancer Network. Neuroendocrine and Adrenal Tumors. NCCN Guidelines
        Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published May 2022.
      </>,
    ],
  },
  {
    gene: 'TGFBR1',
    refrences: [
      'Hiratzka LF, Bakris GL, Beckman JA, et al. 2010 ACCF/AHA/AATS/ACR/ASA/SCA/SCAI/SIR/STS/SVM guidelines for the diagnosis and management of patients with Thoracic Aortic Disease: a report of the American College of Cardiology Foundation/American Heart Association Task Force on Practice Guidelines, American Association for Thoracic Surgery, American College of Radiology, American Stroke Association, Society of Cardiovascular Anesthesiologists, Society for Cardiovascular Angiography and Interventions, Society of Interventional Radiology, Society of Thoracic Surgeons, and Society for Vascular Medicine. Circulation. 2010;13(121):e266-369.',
      <>
        Loeys BL, Dietz HC. Loeys-Dietz Syndrome. 2008 Feb 28 [Updated 2018 Mar 1]. In: Adam MP,
        Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA): University
        of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1133/">
          https://www.ncbi.nlm.nih.gov/books/NBK1133/
        </ExternalLink>
      </>,
      <>
        Milewicz DM, Regalado E. Heritable Thoracic Aortic Disease Overview. 2003 Feb 13 [Updated
        2017 Dec 14]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet].
        Seattle (WA): University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1120/">
          https://www.ncbi.nlm.nih.gov/books/NBK1120/
        </ExternalLink>
      </>,
    ],
  },
  {
    gene: 'WT1',
    refrences: [
      'Boyer O, Schaefer F, Haffner D, Bockenhauer D, Hölttä T, Bérody S, Webb H, Heselden M, Lipska-Zie Tkiewicz BS, Ozaltin F, Levtchenko E, Vivarelli M. Management of congenital nephrotic syndrome: consensus recommendations of the ERKNet-ESPN Working Group. Nat Rev Nephrol. 2021;17:277–89.',
      "Deng C, Dai R, Li X, Liu F. Genetic variation frequencies in Wilms' tumor: A meta-analysis and systematic review. Cancer Sci. 2016;107(5):690-9.",
      <>
        Lipska-Ziętkiewicz BS. WT1 Disorder. 2020 Apr 30 [Updated 2021 Apr 29]. In: Adam MP,
        Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA): University
        of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK556455/">
          https://www.ncbi.nlm.nih.gov/books/NBK556455/
        </ExternalLink>
      </>,
    ],
  },
  {
    gene: 'PCSK9',
    refrences: [
      <>
        Familial Hypercholesterolemia Treatments - The FH Foundation. Accessed August 19, 2022.
        Available at{' '}
        <ExternalLink to="https://thefhfoundation.org/fh-treatments">
          https://thefhfoundation.org/fh-treatments
        </ExternalLink>
      </>,
      'Gidding SS, Champagne MA, de Ferranti SD, Defesche J, Ito MK, Knowles JW, McCrindle B, Raal F, Rader D, Santos RD, Lopes-Virella M, Watts GF, Wierzbicki AS, et al. The agenda for familial hypercholesterolemia: a scientific statement from the American Heart Association. Circulation. 2015;132:2167–92.',
      <>
        Ison HE, Clarke SL, Knowles JW. Familial Hypercholesterolemia. 2014 Jan 2 [Updated 2022 Jul
        7]. In: Adam MP, Everman DB, Mirzaa GM, et al., editors. GeneReviews® [Internet]. Seattle
        (WA): University of Washington, Seattle; 1993-2022. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK174884/">
          https://www.ncbi.nlm.nih.gov/books/NBK174884/
        </ExternalLink>
      </>,
      'Watts GF, Gidding S, Wierzbicki AS, et al. Integrated guidance on the care of familial hypercholesterolaemia from the International FH Foundation. Int J Cardiol. 2014;171(3):309-25.',
    ],
  },
  {
    gene: 'CACNA1S',
    refrences: [
      <>
        ACMG ACT Sheets and Algorithms. Bethesda (MD): American College of Medical Genetics and
        Genomics. Available from:{' '}
        <ExternalLink to="https://www.acmg.net/PDFLibrary/Malignant-Hyperthermia.pdf">
          https://www.acmg.net/PDFLibrary/Malignant-Hyperthermia.pdf
        </ExternalLink>
        . Accessed August 19, 2022.
      </>,
      <>
        Introduction to MH Testing - MHAUS. Available from:{' '}
        <ExternalLink to="https://www.mhaus.org/testing/introduction-to-mh-testing/">
          https://www.mhaus.org/testing/introduction-to-mh-testing/
        </ExternalLink>
        . Accessed August 19, 2022.
      </>,
      <>
        Home - MHAUS. Available from:{' '}
        <ExternalLink to="https://www.mhaus.org/">https://www.mhaus.org/</ExternalLink> . Accessed
        August 19, 2022.
      </>,
      <>
        Rosenberg H, Sambuughin N, Riazi S, et al. Malignant Hyperthermia Susceptibility. 2003 Dec
        19 [Updated 2020 Jan 16]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews®
        [Internet]. Seattle (WA): University of Washington, Seattle; 1993-2020. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1146/">
          https://www.ncbi.nlm.nih.gov/books/NBK1146/
        </ExternalLink>
      </>,
    ],
  },
  {
    gene: 'ACTC1',
    refrences: [
      <>
        Cirino AL, Ho C. Hypertrophic Cardiomyopathy Overview. 2008 Aug 5 [Updated 2021 Jul 8]. In:
        Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA):
        University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1768/">
          https://www.ncbi.nlm.nih.gov/books/NBK1768/
        </ExternalLink>
      </>,
      'Hershberger RE, Lindenfeld J, Mestroni L, et al. Genetic evaluation of cardiomyopathy--a Heart Failure Society of America practice guideline. J Card Fail. 2009;2(15):83-97.',
      'Ommen, S. R., Mital, S., Burke, M. A., Day, S. M., Deswal, A., Elliott, P., Evanovich, L. L., Hung, J., Joglar, J. A., Kantor, P., Kimmelstiel, C., Kittleson, M., Link, M. S., Maron, M. S., Martinez, M. W., Miyake, C. Y., Schaff, H. V., Semsarian, C., & Sorajja, P. (2020). 2020 AHA/ACC Guideline for the Diagnosis and Treatment of Patients With Hypertrophic Cardiomyopathy: A Report of the American College of Cardiology/American Heart Association Joint Committee on Clinical Practice Guidelines. Circulation, 142(25), e558–e631.',
    ],
  },
  {
    gene: 'MYL2',
    refrences: [
      <>
        Cirino AL, Ho C. Hypertrophic Cardiomyopathy Overview. 2008 Aug 5 [Updated 2021 Jul 8]. In:
        Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA):
        University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1768/">
          https://www.ncbi.nlm.nih.gov/books/NBK1768/
        </ExternalLink>
      </>,
      'Hershberger RE, Lindenfeld J, Mestroni L, et al. Genetic evaluation of cardiomyopathy--a Heart Failure Society of America practice guideline. J Card Fail. 2009;2(15):83-97.',
      'Ommen, S. R., Mital, S., Burke, M. A., Day, S. M., Deswal, A., Elliott, P., Evanovich, L. L., Hung, J., Joglar, J. A., Kantor, P., Kimmelstiel, C., Kittleson, M., Link, M. S., Maron, M. S., Martinez, M. W., Miyake, C. Y., Schaff, H. V., Semsarian, C., & Sorajja, P. (2020). 2020 AHA/ACC Guideline for the Diagnosis and Treatment of Patients With Hypertrophic Cardiomyopathy: A Report of the American College of Cardiology/American Heart Association Joint Committee on Clinical Practice Guidelines. Circulation, 142(25), e558–e631.',
    ],
  },
  {
    gene: 'ACTA2',
    refrences: [
      'Hiratzka LF, Bakris GL, Beckman JA, et al. 2010 ACCF/AHA/AATS/ACR/ASA/SCA/SCAI/SIR/STS/SVM guidelines for the diagnosis and management of patients with Thoracic Aortic Disease: a report of the American College of Cardiology Foundation/American Heart Association Task Force on Practice Guidelines, American Association for Thoracic Surgery, American College of Radiology, American Stroke Association, Society of Cardiovascular Anesthesiologists, Society for Cardiovascular Angiography and Interventions, Society of Interventional Radiology, Society of Thoracic Surgeons, and Society for Vascular Medicine. Circulation. 2010;13(121):e266-369.',
      <>
        Milewicz DM, Regalado E. Heritable Thoracic Aortic Disease Overview. 2003 Feb 13 [Updated
        2017 Dec 14]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet].
        Seattle (WA): University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1120/">
          https://www.ncbi.nlm.nih.gov/books/NBK1120/
        </ExternalLink>
      </>,
    ],
  },
  {
    gene: 'PRKAG2',
    refrences: [
      <>
        Cirino AL, Ho C. Hypertrophic Cardiomyopathy Overview. 2008 Aug 5 [Updated 2021 Jul 8]. In:
        Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA):
        University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1768/">
          https://www.ncbi.nlm.nih.gov/books/NBK1768/
        </ExternalLink>
      </>,
      'Hershberger RE, Lindenfeld J, Mestroni L, et al. Genetic evaluation of cardiomyopathy--a Heart Failure Society of America practice guideline. J Card Fail. 2009;2(15):83-97.',
      'Ommen, S. R., Mital, S., Burke, M. A., Day, S. M., Deswal, A., Elliott, P., Evanovich, L. L., Hung, J., Joglar, J. A., Kantor, P., Kimmelstiel, C., Kittleson, M., Link, M. S., Maron, M. S., Martinez, M. W., Miyake, C. Y., Schaff, H. V., Semsarian, C., & Sorajja, P. (2020). 2020 AHA/ACC Guideline for the Diagnosis and Treatment of Patients With Hypertrophic Cardiomyopathy: A Report of the American College of Cardiology/American Heart Association Joint Committee on Clinical Practice Guidelines. Circulation, 142(25), e558–e631.',
    ],
  },
  {
    gene: 'MYH11',
    refrences: [
      'Hiratzka LF, Bakris GL, Beckman JA, et al. 2010 ACCF/AHA/AATS/ACR/ASA/SCA/SCAI/SIR/STS/SVM guidelines for the diagnosis and management of patients with Thoracic Aortic Disease: a report of the American College of Cardiology Foundation/American Heart Association Task Force on Practice Guidelines, American Association for Thoracic Surgery, American College of Radiology, American Stroke Association, Society of Cardiovascular Anesthesiologists, Society for Cardiovascular Angiography and Interventions, Society of Interventional Radiology, Society of Thoracic Surgeons, and Society for Vascular Medicine. Circulation. 2010;13(121):e266-369.',
      <>
        Milewicz DM, Regalado E. Heritable Thoracic Aortic Disease Overview. 2003 Feb 13 [Updated
        2017 Dec 14]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet].
        Seattle (WA): University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1120/">
          https://www.ncbi.nlm.nih.gov/books/NBK1120/
        </ExternalLink>
      </>,
    ],
  },
  {
    gene: 'MYL3',
    refrences: [
      <>
        Cirino AL, Ho C. Hypertrophic Cardiomyopathy Overview. 2008 Aug 5 [Updated 2021 Jul 8]. In:
        Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA):
        University of Washington, Seattle; 1993-2021. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1768/">
          https://www.ncbi.nlm.nih.gov/books/NBK1768/
        </ExternalLink>
      </>,
      'Hershberger RE, Lindenfeld J, Mestroni L, et al. Genetic evaluation of cardiomyopathy--a Heart Failure Society of America practice guideline. J Card Fail. 2009;2(15):83-97.',
      'Ommen, S. R., Mital, S., Burke, M. A., Day, S. M., Deswal, A., Elliott, P., Evanovich, L. L., Hung, J., Joglar, J. A., Kantor, P., Kimmelstiel, C., Kittleson, M., Link, M. S., Maron, M. S., Martinez, M. W., Miyake, C. Y., Schaff, H. V., Semsarian, C., & Sorajja, P. (2020). 2020 AHA/ACC Guideline for the Diagnosis and Treatment of Patients With Hypertrophic Cardiomyopathy: A Report of the American College of Cardiology/American Heart Association Joint Committee on Clinical Practice Guidelines. Circulation, 142(25), e558–e631.',
    ],
  },
  {
    gene: 'SDHAF2',
    refrences: [
      <>
        Else T, Greenberg S, Fishbein L. Hereditary Paraganglioma-Pheochromocytoma Syndromes. 2008
        May 21 [Updated 2018 Oct 4]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors.
        GeneReviews® [Internet]. Seattle (WA): University of Washington, Seattle; 1993-2020.
        Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1548/">
          https://www.ncbi.nlm.nih.gov/books/NBK1548/
        </ExternalLink>
      </>,
      'Fishbein L, Nathanson KL. Pheochromocytoma and paraganglioma: understanding the complexities of the genetic background. Cancer Genet. 2012;205(1-2):1-11.',
      'Muth A, Crona J, Gimm O, et al. Genetic testing and surveillance guidelines in hereditary pheochromocytoma and paraganglioma. J Intern Med. 2019;285(2):187-204.',
      'Rednam SP, Erez A, Druker H, et al. Von Hippel-Lindau and Hereditary Pheochromocytoma/Paraganglioma Syndromes: Clinical Features, Genetics, and Surveillance Recommendations in Childhood. Clin Cancer Res. 2017;23(12):e68-e75.',
      <>
        National Comprehensive Cancer Network. Neuroendocrine and Adrenal Tumors. NCCN Guidelines
        Version 1.2022. Available at{' '}
        <ExternalLink to="https://www.nccn.org/">www.nccn.org</ExternalLink>. Published May 2022.
      </>,
    ],
  },
  {
    gene: 'TMEM43',
    refrences: [
      <>
        Hershberger RE, Lindenfeld J, Mestroni L, et al. Genetic evaluation of cardiomyopathy--a
        Heart Failure Society of America practice guideline. J Card Fail. 2009;2(15):83-97.
      </>,
      <>
        McNally E, MacLeod H, Dellefave-Castillo L. Arrhythmogenic Right Ventricular Cardiomyopathy.
        2005 Apr 18 [Updated 2017 May 25]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors.
        GeneReviews® [Internet]. Seattle (WA): University of Washington, Seattle; 1993-2020.
        Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK1131/">
          https://www.ncbi.nlm.nih.gov/books/NBK1131/
        </ExternalLink>
      </>,
      <>
        Towbin JA, McKenna WJ, Abrams DJ, et al. 2019 HRS expert consensus statement on evaluation,
        risk stratification, and management of arrhythmogenic cardiomyopathy. Heart Rhythm.
        2019;16(11):e301-e372.
      </>,
    ],
  },
  {
    gene: 'APOB',
    refrences: [
      <>
        Familial Hypercholesterolemia Treatments - The FH Foundation. Accessed August 19, 2022.
        Available at{' '}
        <ExternalLink to="https://thefhfoundation.org/fh-treatments">
          https://thefhfoundation.org/fh-treatments
        </ExternalLink>
      </>,
      'Gidding SS, Champagne MA, de Ferranti SD, Defesche J, Ito MK, Knowles JW, McCrindle B, Raal F, Rader D, Santos RD, Lopes-Virella M, Watts GF, Wierzbicki AS, et al. The agenda for familial hypercholesterolemia: a scientific statement from the American Heart Association. Circulation. 2015;132:2167–92.',
      <>
        Ison HE, Clarke SL, Knowles JW. Familial Hypercholesterolemia. 2014 Jan 2 [Updated 2022 Jul
        7]. In: Adam MP, Everman DB, Mirzaa GM, et al., editors. GeneReviews® [Internet]. Seattle
        (WA): University of Washington, Seattle; 1993-2022. Available from:{' '}
        <ExternalLink to="https://www.ncbi.nlm.nih.gov/books/NBK174884/">
          https://www.ncbi.nlm.nih.gov/books/NBK174884/
        </ExternalLink>
      </>,
      'Watts GF, Gidding S, Wierzbicki AS, et al. Integrated guidance on the care of familial hypercholesterolaemia from the International FH Foundation. Int J Cardiol. 2014;171(3):309-25.',
    ],
  },
];
