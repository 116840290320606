import { ExternalLink, Spacer } from '@color/continuum';
import { Fragment } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

import { AncestryData } from 'components/AncestryResults/types';
import { usePreResultsScreenWasCompleted } from 'components/common/InformingLoop/PreResultsScreen/usePreResultsScreenWasCompleted';
import { ReportIconType } from 'components/common/Reports/ReportIcon/types';
import { ResultCard } from 'components/common/ResultCard';
import { Trait, TraitOutcomeData } from 'components/TraitResults/types';
import { useConfigForIdp } from 'hooks/useConfigForIdp';
import { ResultDecision } from 'lib/analytics/result-decisions/types';
import { ModuleType } from 'lib/analytics/types';
import { areIlLoopsDisabled } from 'lib/waffle/util';

import { TRAIT_RESULT_CARD_CONTENT } from './content';

const messages = defineMessages({
  informingLoopCardTitle: {
    defaultMessage: 'Choose to get genetic ancestry and trait results',
  },
  informingLoopCardSubtitle: {
    defaultMessage:
      'Review the benefits and risks of getting your DNA results for genetic ancestry and traits, and decide if you want to see them.',
  },
  noInformingLoopCardTitle: {
    defaultMessage: 'Genetic ancestry and trait results are no longer available.',
  },
  noInformingLoopCardSubtitle: {
    defaultMessage:
      'We are making changes to how we return information to participants. Stay tuned for more updates from <i>All of Us.</i>',
  },
  getStarted: {
    defaultMessage: 'Get Started',
  },
  getStartedAccessibleButtonText: {
    defaultMessage: 'Get started with genetic ancestry and trait results',
  },
  informingLoopCardAfterNoDecisionTitle: {
    defaultMessage: "You've chosen not to get genetic ancestry and trait results",
  },
  informingLoopCardAfterNoDecisionSubtitle: {
    defaultMessage:
      "On {date}, you decided not to receive your genetic ancestry and trait DNA results. If you'd like to change your decision, you can review your options again.",
  },
  reviewOptions: {
    defaultMessage: 'Review Options',
  },
  reviewOptionsAccessibleButtonText: {
    defaultMessage: 'Review options for genetic ancestry and trait results',
  },
  ancestryCardTitle: {
    defaultMessage: 'Genetic ancestry',
  },
  viewResults: {
    defaultMessage: 'View Results',
  },
  viewResultsAccessibleButtonText: {
    defaultMessage: 'View genetic ancestry and trait results',
  },
});

interface Props {
  resultDecision?: ResultDecision;
}

export const ResultCards: React.FC<Props> = (props: Props) => {
  const location = useLocation();
  const { resultDecision } = props;

  const queryClient = useQueryClient();
  const intl = useIntl();
  const { preResultsScreenWasCompleted, isLoading: preResultsScreenStatusLoading } =
    usePreResultsScreenWasCompleted({
      moduleType: ModuleType.GEM,
    });
  const ancestryData = queryClient.getQueryData<AncestryData>('ancestry', { exact: false });
  const traitOutcomesData = queryClient.getQueryData<Record<Trait, TraitOutcomeData>>([
    'trait_outcomes',
    {},
  ]);

  const { LEARNING_CENTER_URL } = useConfigForIdp();
  const showDisabledIlText = areIlLoopsDisabled();

  if (!resultDecision) {
    const title =
      showDisabledIlText === false
        ? intl.formatMessage(messages.informingLoopCardTitle)
        : intl.formatMessage(messages.noInformingLoopCardTitle);
    const subtitle =
      showDisabledIlText === false
        ? intl.formatMessage(messages.informingLoopCardSubtitle)
        : intl.formatMessage(messages.noInformingLoopCardSubtitle);
    const buttonProps = showDisabledIlText
      ? {}
      : {
          buttonText: intl.formatMessage(messages.getStarted),
          buttonAriaLabel: intl.formatMessage(messages.getStartedAccessibleButtonText),
        };

    return (
      <ResultCard
        icon={ReportIconType.ANCESTRY_AND_TRAITS}
        title={title}
        subtitle={subtitle}
        buttonLinkTo={{
          pathname: '/welcome/ancestry-and-traits',
          state: { backgroundLocation: location },
        }}
        {...buttonProps}
        resultIsViewed={false}
      />
    );
  }

  if (!resultDecision.resultVisible) {
    const title =
      showDisabledIlText === false
        ? intl.formatMessage(messages.informingLoopCardAfterNoDecisionTitle)
        : intl.formatMessage(messages.noInformingLoopCardTitle);
    const subtitle =
      showDisabledIlText === false
        ? intl.formatMessage(messages.informingLoopCardAfterNoDecisionSubtitle, {
            date: intl.formatDate(new Date(resultDecision.decisionAt), {
              year: '2-digit',
              month: 'numeric',
              day: 'numeric',
            }),
          })
        : intl.formatMessage(messages.noInformingLoopCardSubtitle);
    const buttonProps = showDisabledIlText
      ? {}
      : {
          buttonText: intl.formatMessage(messages.reviewOptionsAccessibleButtonText),
          buttonAriaLabel: intl.formatMessage(messages.reviewOptionsAccessibleButtonText),
        };

    return (
      <ResultCard
        icon={ReportIconType.ANCESTRY_AND_TRAITS}
        title={title}
        subtitle={subtitle}
        buttonLinkTo={{
          pathname: '/welcome/ancestry-and-traits',
          state: { backgroundLocation: location },
        }}
        {...buttonProps}
        resultIsViewed
      />
    );
  }

  if (preResultsScreenStatusLoading) {
    return null;
  }
  return (
    <>
      {ancestryData && (
        <>
          <ResultCard
            icon={ReportIconType.ANCESTRY}
            title={intl.formatMessage(messages.ancestryCardTitle)}
            subtitle={
              <FormattedMessage
                description="Ancestry view results card content"
                defaultMessage="Genetic ancestry can be very interesting, but you may also learn information you didn’t expect. {learnMoreLink}"
                values={{
                  learnMoreLink: (
                    <ExternalLink to={LEARNING_CENTER_URL.href} linkShouldOpenInCurrentTab>
                      <FormattedMessage
                        description="Learn more link text"
                        defaultMessage="Learn more"
                      />
                    </ExternalLink>
                  ),
                }}
              />
            }
            buttonLinkTo={
              preResultsScreenWasCompleted
                ? '/ancestry'
                : { pathname: '/welcome/ancestry', state: { backgroundLocation: location } }
            }
            buttonText={intl.formatMessage(messages.viewResults)}
            buttonAriaLabel={intl.formatMessage(messages.viewResultsAccessibleButtonText)}
            resultIsViewed={preResultsScreenWasCompleted && ancestryData.hasViewedAncestry}
          />
          <Spacer variant="small" />
        </>
      )}
      {traitOutcomesData &&
        Object.keys(traitOutcomesData)
          .filter((trait) => TRAIT_RESULT_CARD_CONTENT[trait as Trait])
          .sort()
          .map((trait: string, index: number) => (
            <Fragment key={trait}>
              {index !== 0 && <Spacer variant="small" />}
              <ResultCard
                icon={trait as ReportIconType}
                title={intl.formatMessage(TRAIT_RESULT_CARD_CONTENT[trait as Trait].title)}
                subtitle={intl.formatMessage(TRAIT_RESULT_CARD_CONTENT[trait as Trait].subtitle)}
                buttonLinkTo={`/results/${trait}`}
                buttonText={intl.formatMessage(messages.viewResults)}
                buttonAriaLabel={intl.formatMessage(
                  TRAIT_RESULT_CARD_CONTENT[trait as Trait].accessibleButtonText
                )}
                resultIsViewed={traitOutcomesData[trait as Trait].hasViewedTrait}
              />
            </Fragment>
          ))}
    </>
  );
};
