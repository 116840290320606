import { Container, Spacer } from '@color/continuum';
import { useIntl } from 'react-intl';
import { Redirect, useParams } from 'react-router-dom';

import { BackButtonBar } from 'components/common/BackButtonBar';
import { AncestryAndTraitsOtherResultsSection } from 'components/common/Reports/AncestryAndTraitsOtherResultsSection/AncestryAndTraitsOtherResultsSection';
import { QuizAndMoreInformationSection } from 'components/common/Reports/QuizAndMoreInformationSection';
import { SimplePage } from 'components/common/SimplePage/SimplePage';

import { useTraitOutcomeQuery } from './api';
import { TraitResultsDisclaimer } from './components/TraitResultsDisclaimer';
import { TraitResultsExpansionPanel } from './components/TraitResultsExpansionPanel';
import { TraitResultsHeader } from './components/TraitResultsHeader';
import { TRAIT_RESULTS_HEADER_CONTENT } from './components/TraitResultsHeader/content';
import { TraitResultsOutcomeBanner } from './components/TraitResultsOutcomeBanner';
import { TraitResultsReferences } from './components/TraitResultsReferences';
import { TraitResultsSection } from './components/TraitResultsSection';
import { TraitResultsSectionSeparator } from './components/TraitResultsSectionSeparator';
import { Trait, TraitOutcome } from './types';

export const TraitResults: React.FC = () => {
  const { trait } = useParams<{ trait: Trait }>();
  const intl = useIntl();
  const {
    data: traitOutcomeData,
    error,
    isLoading,
  } = useTraitOutcomeQuery({
    trait,
  });

  if (isLoading) {
    return null;
  }

  if (error) {
    return <Redirect to="/" />;
  }

  const traitOutcome = traitOutcomeData as TraitOutcome;
  const pageTitle = `${intl.formatMessage(TRAIT_RESULTS_HEADER_CONTENT[trait].title)}`;

  return (
    <SimplePage helmetTitle={pageTitle}>
      <BackButtonBar to="/" />
      <Spacer variant="xLarge" />
      <TraitResultsHeader trait={trait} />
      <Spacer variant="xxLarge" />
      {traitOutcome?.outcome && (
        <>
          <TraitResultsOutcomeBanner trait={trait} outcome={traitOutcome.outcome} />
          <Spacer variant="xLarge" />
          <TraitResultsSection section={1} trait={trait} traitOutcome={traitOutcome} />
          <Spacer variant="xLarge" />
          <TraitResultsSectionSeparator />
          <Spacer variant="xLarge" />
          <TraitResultsSection section={2} trait={trait} traitOutcome={traitOutcome} />
          <Spacer variant="xLarge" />
          <TraitResultsExpansionPanel panel={1} trait={trait} />
          <Spacer variant="xLarge" />
          <TraitResultsSection section={3} trait={trait} traitOutcome={traitOutcome} />
          <Spacer variant="xLarge" />
          <TraitResultsExpansionPanel panel={2} trait={trait} />
          <Spacer variant="xLarge" />
          <Spacer variant="xLarge" />
          <Container>
            <QuizAndMoreInformationSection
              quizName={`traits_survey_${trait === Trait.BITTERNESS ? 'bitter' : trait}_v2`}
              moreInformationSection={<AncestryAndTraitsOtherResultsSection />}
            />
          </Container>
          <Spacer variant="xLarge" />
          <Spacer variant="xLarge" />
          <TraitResultsDisclaimer trait={trait} />
          <TraitResultsReferences trait={trait} />
        </>
      )}
    </SimplePage>
  );
};
