import { Button, useLocationFlowContext } from '@color/continuum';
import { LocationDescriptorObject } from 'history';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { useCreateResultDecisionMutation } from 'lib/analytics/result-decisions';
import { ResultDecisionValue } from 'lib/analytics/result-decisions/types';
import { ModuleType } from 'lib/analytics/types';

import { InformingLoopFooter } from '../InformingLoopFooter/InformingLoopFooter';

interface Props {
  decision?: ResultDecisionValue;
  // On the Dashboard, we make calls to the backend to get results data (e.g. ancestry results, trait results).
  // We do this to check if a user has data available before showing cards on the Dashboard.
  // These calls will return nothing if the user has not agreed to the Informing Loop.
  // Since those calls will be cached, we need to invalidate relevant queries when the user completes the Informing Loop,
  // so that when they return to the Dashboard we will re-fetch the data and show the cards.
  queriesInvalidatedAfterDecisionSaved?: string[];
  moduleType: ModuleType;
}

export const DecisionModuleInformingLoopFooter: React.FC<Props> = (props) => {
  const { decision, queriesInvalidatedAfterDecisionSaved, moduleType } = props;
  const history = useHistory();
  const queryClient = useQueryClient();
  const { goToPreviousLocation, nextLocation } = useLocationFlowContext();
  const { mutateAsync: saveResultDecision, isLoading: saveResultDecisionQueryHasStarted } =
    useCreateResultDecisionMutation();
  return (
    <InformingLoopFooter
      leftButton={
        <Button variant="color-secondary" onClick={() => goToPreviousLocation()}>
          <FormattedMessage
            description="Informing loop footer previous button text"
            defaultMessage="Previous"
          />
        </Button>
      }
      rightButton={
        <Button
          disabled={!decision || saveResultDecisionQueryHasStarted === true}
          onClick={async () => {
            if (!decision) {
              return;
            }

            await saveResultDecision({
              moduleType,
              decisionValue: decision,
            });
            // TODO(ALLOFUS-2452): Do something on error when we have the UX.

            queriesInvalidatedAfterDecisionSaved?.forEach((queryName) => {
              queryClient.invalidateQueries(queryName);
            });

            history.push({
              pathname: nextLocation?.pathname,
              // We change underscores to dashes to keep a consistent URL format using dashes.
              state: {
                ...(nextLocation?.state as LocationDescriptorObject),
                decision: decision.replace('_', '-'),
              },
            });
          }}
        >
          <FormattedMessage
            defaultMessage="Submit"
            description="Informing loop footer submit button text"
          />
        </Button>
      }
    />
  );
};
