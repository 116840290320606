import { Banner, Container, Grid, InternalLink, Spacer, Text, Title } from '@color/continuum';
import { isEmpty, some } from 'lodash';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { BreadcrumbsBar } from 'components/common/BreadcrumbsBar';
import { SimplePage } from 'components/common/SimplePage/SimplePage';
import { useHgmInformingLoopsEligibility } from 'components/InformingLoops/useHgmInformingLoopsEligibility';
import { useResultDecisionsQuery } from 'lib/analytics/result-decisions';
import { ModuleType } from 'lib/analytics/types';
import { areIlLoopsDisabled, isIncidentBannerWaffleEnabled } from 'lib/waffle/util';

import { AncestryAndTraitResults } from './AncestryAndTraitResults';
import { useResultStatuses } from './api';
import { HealthRelatedResults } from './HealthRelatedResults/HealthRelatedResults';
import { ResourceCard } from './ResourceCard';
import { ResultsFilter } from './ResultsFilter/ResultsFilter';
import { useResultsFilterState } from './ResultsFilter/useResultsFilterState';

const messages = defineMessages({
  dashboardPageTitle: {
    description: 'Dashboard Page Title',
    defaultMessage: 'DNA Results Home',
  },
});

export const Dashboard: React.FC = () => {
  const { gemResultsAreReady, hdrResultsAreReady, pgxResultsAreReady } = useResultStatuses();
  const { data: resultDecisionsData } = useResultDecisionsQuery({ moduleType: ModuleType.GEM });
  const hideIlLoops = areIlLoopsDisabled();
  const gemResultsDecisionWasMade = isEmpty(resultDecisionsData?.resultDecisions)
    ? false
    : some(resultDecisionsData!.resultDecisions, 'resultVisible');
  const showOptionsForYourDnaLink =
    !hideIlLoops && (gemResultsDecisionWasMade || hdrResultsAreReady || pgxResultsAreReady);
  const userEligibleForHgmInformingLoops = useHgmInformingLoopsEligibility();
  const {
    shouldShowAncestryAndTraitResults,
    shouldShowHealthRelatedResults,
    resultsFilterSelection,
    onChangeResultsFilterSelection,
  } = useResultsFilterState();

  const intl = useIntl();

  return (
    <SimplePage helmetTitle={intl.formatMessage(messages.dashboardPageTitle)}>
      <BreadcrumbsBar />
      {isIncidentBannerWaffleEnabled() && (
        <Container>
          <Spacer variant="medium" />
          <Banner
            severity="warning"
            variant="in-component"
            sx={{
              marginLeft: 5.5,
              marginTop: 0,
            }}
          >
            <Text>
              <FormattedMessage id="Dashboard_Error_Banner" />
            </Text>
          </Banner>
          <Spacer variant="medium" />
        </Container>
      )}
      <Spacer variant="small" />
      <Container>
        <Title>
          <FormattedMessage defaultMessage="DNA Results" />
        </Title>
        <Spacer variant="small" />
        <Text>
          {showOptionsForYourDnaLink ? (
            <FormattedMessage
              defaultMessage="You’ll see all of your DNA results here when they’re ready. See <a>options for your DNA results.</a>"
              description="Text at top of Genomics Platform dashboard"
              values={{
                a: (chunks: React.ReactNode) => <InternalLink to="/options">{chunks}</InternalLink>,
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="You’ll see all of your DNA results here when they’re ready."
              description="Text at top of Genomics Platform dashboard without options"
            />
          )}
        </Text>
        <Spacer variant="xxLarge" />
        {gemResultsAreReady && userEligibleForHgmInformingLoops && (
          <ResultsFilter
            resultsFilterSelection={resultsFilterSelection}
            onChangeResultsFilterSelection={onChangeResultsFilterSelection}
          />
        )}
        <Spacer variant="xLarge" />
        {userEligibleForHgmInformingLoops && shouldShowHealthRelatedResults && (
          <>
            <HealthRelatedResults />
            <Spacer variant="xxxLarge" />
          </>
        )}
        {gemResultsAreReady && shouldShowAncestryAndTraitResults && (
          <>
            <AncestryAndTraitResults />
            <Spacer variant="xxxLarge" />
          </>
        )}
        <Title variant="headline2">
          <FormattedMessage
            defaultMessage="Additional Resources"
            description="Title for additional resources on dashboard page."
          />
        </Title>
        <Spacer variant="large" />
        <Grid container>
          <Grid item xs={12} md={4}>
            <ResourceCard />
          </Grid>
        </Grid>
        <Spacer variant="xLarge" />
        <Spacer variant="xLarge" />
      </Container>
    </SimplePage>
  );
};
