import { NavigationAnnouncer } from '@color/continuum';
import { Location } from 'history';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { AncestryResults } from 'components/AncestryResults/AncestryResults';
import { Appointments } from 'components/Appointments/Appointments';
import { usePreResultsScreenWasCompleted } from 'components/common/InformingLoop/PreResultsScreen/usePreResultsScreenWasCompleted';
import { LocationWithBackground } from 'components/common/types';
import { useResultStatuses } from 'components/Dashboard/api';
import { Dashboard } from 'components/Dashboard/Dashboard';
import { DownloadReport } from 'components/HealthRelatedResults/DownloadReport';
import { HdrReport } from 'components/HealthRelatedResults/HdrReport';
import { ShareResultsWithProviderForm } from 'components/HealthRelatedResults/HdrReport/HdrPositiveReport/ShareResultsWithProviderForm';
import { PgxReport } from 'components/HealthRelatedResults/PgxReport/PgxReport';
import { AncestryInformingLoop } from 'components/InformingLoops/AncestryInformingLoop/AncestryInformingLoop';
import { GemInformingLoop } from 'components/InformingLoops/GemInformingLoop/GemInformingLoop';
import { HdrInformingLoop } from 'components/InformingLoops/HdrInformingLoop/HdrInformingLoop';
import { HdrPreResults } from 'components/InformingLoops/HdrPreResults/HdrPreResults';
import { PgxInformingLoop } from 'components/InformingLoops/PgxInformingLoop/PgxInformingLoop';
import { PgxPreResults } from 'components/InformingLoops/PgxPreResults/PgxPreResults';
import { useHgmInformingLoopsEligibility } from 'components/InformingLoops/useHgmInformingLoopsEligibility';
import { OptionsForResults } from 'components/OptionsForResults/OptionsForResults';
import { TraitResults } from 'components/TraitResults/TraitResults';
import { useResultsVisible } from 'lib/analytics/result-decisions/api';
import { ModuleType } from 'lib/analytics/types';
import { areIlLoopsDisabled } from 'lib/waffle/util';

import {
  DOWNLOAD_PDF_PAGE_PATH,
  GEM_INFORMING_LOOP_PATH,
  HDR_APPOINTMENT_SCHEDULING_PATH,
  HDR_INFORMING_LOOP_PATH,
  HDR_PRE_RESULTS_SCREEN_PATH,
  HDR_REPORT_PATH,
  PGX_APPOINTMENT_SCHEDULING_PATH,
  PGX_INFORMING_LOOP_PATH,
  PGX_PRE_RESULTS_SCREEN_PATH,
  PGX_REPORT_PATH,
  SHARE_RESULTS_WITH_PROVIDER_PATH,
} from './constants';

export const AuthenticatedRoutes: React.FC = () => {
  const location = useLocation<LocationWithBackground>();
  // Contains the location of the background of a modal, so we can render both for a snappier feel.
  // See https://reactrouter.com/web/example/modal-gallery for more details.
  const backgroundLocation = location.state && location?.state.backgroundLocation;
  const userEligibleForHgmInformingLoops = useHgmInformingLoopsEligibility();
  const {
    gemResultsAreReady,
    pgxResultsAreReady,
    hdrResultsAreReady,
    pgxResultsAreOnHold,
    hdrResultsAreOnHold,
  } = useResultStatuses();
  const someResultsAreReady = gemResultsAreReady || pgxResultsAreReady || hdrResultsAreReady;
  const { hdrIsVisible, pgxIsVisible } = useResultsVisible();
  const { preResultsScreenWasCompleted: isGemPreResultsScreenCompleted } =
    usePreResultsScreenWasCompleted({
      moduleType: ModuleType.GEM,
    });

  const hideIlLoops = areIlLoopsDisabled();

  return (
    <>
      <Switch location={(backgroundLocation as Location) || (location as Location)}>
        <Route exact path="/">
          <Dashboard />
        </Route>
        {/** The Ancestry page requires the GEM results to be ready, and for the
         * pre results screen to be completed.
         */}
        {gemResultsAreReady && isGemPreResultsScreenCompleted && (
          <Route path="/ancestry">
            <AncestryResults />
          </Route>
        )}
        {/** The traits page does not require the pre results screen to be completed. */}
        {gemResultsAreReady && (
          <Route path="/results/:trait">
            <TraitResults />
          </Route>
        )}
        <Route path={DOWNLOAD_PDF_PAGE_PATH}>
          <DownloadReport />
        </Route>
        {!hideIlLoops && someResultsAreReady && (
          <Route path="/options">
            <OptionsForResults />
          </Route>
        )}
        {hdrResultsAreReady &&
          hdrIsVisible &&
          !hdrResultsAreOnHold && [
            <Route path={HDR_APPOINTMENT_SCHEDULING_PATH}>
              <Appointments />
            </Route>,
            <Route path={HDR_REPORT_PATH}>
              <HdrReport />
            </Route>,
          ]}

        {pgxResultsAreReady &&
          pgxIsVisible &&
          !pgxResultsAreOnHold && [
            <Route path={PGX_APPOINTMENT_SCHEDULING_PATH}>
              <Appointments />
            </Route>,
            <Route path={PGX_REPORT_PATH}>
              <PgxReport />
            </Route>,
          ]}
        {/**
         * The Informing Loop dialogs are rendered on top of the background location.
         * If a user navigated to the Informing Loop from within GP, this will be one of the pages
         * above. However, if the user navigated directly to the Informing Loop from an external link
         * (they do this from PTSC), the backgroundLocation will be null and thus we will render the
         * "Not Found" text in the background, which can be visible to users for a moment. This is a workaround
         * to prevent the "Not Found" text from appearing, by just rendering a blank page on the routes for the
         * Informing Loops.
         */}
        {!hideIlLoops && gemResultsAreReady && <Route path={GEM_INFORMING_LOOP_PATH} />}
        {!hideIlLoops &&
          userEligibleForHgmInformingLoops && [
            <Route path={HDR_INFORMING_LOOP_PATH} />,
            <Route path={PGX_INFORMING_LOOP_PATH} />,
          ]}
        {/**
         * When the informing loops are disabled, we want to redirect from the IL
         * pages to the dashboard (there are some remaining links to the IL pages in
         * emails).
         */}
        {hideIlLoops && (
          <>
            <Route path={GEM_INFORMING_LOOP_PATH}>
              <Redirect to="/" />
            </Route>
            <Route path={HDR_INFORMING_LOOP_PATH}>
              <Redirect to="/" />
            </Route>
            <Route path={PGX_INFORMING_LOOP_PATH}>
              <Redirect to="/" />
            </Route>
            <Route path="/welcome/ancestry">
              <Redirect to="/" />
            </Route>
          </>
        )}
        <Route>Not Found</Route>
      </Switch>
      {/** The Informing Loops are rendered outside of the switch so that they can be rendered
       * on top of the background. By not having to re-render the background when the Informing Loop
       * is closed, we get a snappier feeling to the app. */}
      {!hideIlLoops &&
        gemResultsAreReady && [
          <Route path={GEM_INFORMING_LOOP_PATH}>
            <GemInformingLoop />
          </Route>,
          <Route path="/welcome/ancestry">
            <AncestryInformingLoop />
          </Route>,
        ]}
      {hdrResultsAreReady && hdrIsVisible && (
        <>
          <Route path={HDR_PRE_RESULTS_SCREEN_PATH}>
            <HdrPreResults />
          </Route>
          <Route path={SHARE_RESULTS_WITH_PROVIDER_PATH}>
            <ShareResultsWithProviderForm />
          </Route>
        </>
      )}

      {pgxResultsAreReady && pgxIsVisible && (
        <Route path={PGX_PRE_RESULTS_SCREEN_PATH}>
          <PgxPreResults />
        </Route>
      )}
      {!hideIlLoops &&
        userEligibleForHgmInformingLoops && [
          <Route path={HDR_INFORMING_LOOP_PATH}>
            <HdrInformingLoop />
          </Route>,
          <Route path={PGX_INFORMING_LOOP_PATH}>
            <PgxInformingLoop />
          </Route>,
        ]}
      <NavigationAnnouncer />
    </>
  );
};
