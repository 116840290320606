import { MoreInformationCard, Spacer, Title } from '@color/continuum';
import { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { useAncestryQuery } from 'components/AncestryResults/api';
import { usePreResultsScreenWasCompleted } from 'components/common/InformingLoop/PreResultsScreen/usePreResultsScreenWasCompleted';
import { ReportIconType } from 'components/common/Reports/ReportIcon/types';
import { useTraitOutcomeQuery } from 'components/TraitResults/api';
import { ModuleType } from 'lib/analytics/types';

import { getReportIconSrc } from '../ReportIcon/utils';
import { OTHER_RESULTS_CARD_CONTENT } from './content';

export const AncestryAndTraitsOtherResultsSection: React.FC = () => {
  const location = useLocation();
  const reportName = location.pathname.split('/').pop();
  const intl = useIntl();
  const { data: traitOutcomeData } = useTraitOutcomeQuery();
  const { data: ancestryData } = useAncestryQuery();
  const { preResultsScreenWasCompleted, isLoading: preResultsScreenStatusLoading } =
    usePreResultsScreenWasCompleted({
      moduleType: ModuleType.GEM,
    });
  if (preResultsScreenStatusLoading) {
    return null;
  }

  return (
    <>
      <Title>
        <FormattedMessage
          description="Other genetic ancestry and trait results section title"
          defaultMessage="View other genetic ancestry and trait results"
        />
      </Title>
      <Spacer variant="large" />
      {ancestryData?.compositions && reportName !== 'ancestry' && (
        <>
          <MoreInformationCard
            title={
              <FormattedMessage
                description="Other genetic ancestry and trait results ancestry title"
                defaultMessage="Genetic Ancestry"
              />
            }
            iconSrc={getReportIconSrc(ReportIconType.ANCESTRY)}
            to={preResultsScreenWasCompleted ? '/ancestry' : '/welcome/ancestry'}
          />
          <Spacer variant="medium" />
        </>
      )}
      <>
        {traitOutcomeData &&
          Object.keys(traitOutcomeData)
            .sort()
            .map(
              (key) =>
                key !== reportName && (
                  <Fragment key={key}>
                    <MoreInformationCard
                      title={intl.formatMessage(
                        OTHER_RESULTS_CARD_CONTENT[key as keyof typeof OTHER_RESULTS_CARD_CONTENT]
                          .title
                      )}
                      iconSrc={getReportIconSrc(
                        OTHER_RESULTS_CARD_CONTENT[key as keyof typeof OTHER_RESULTS_CARD_CONTENT]
                          .icon
                      )}
                      to={`/results/${key}`}
                    />
                    <Spacer variant="medium" />
                  </Fragment>
                )
            )}
      </>
    </>
  );
};
