import { Environment, getEnvironmentOrDefault } from '@color/lib';

import { AppConfig, AppEnvironment } from './types';

/* eslint-disable global-require */
const ENVIRONMENT_CONFIGS: Record<
  AppEnvironment,
  () => { config: AppConfig; getIdpOverrides: (idp: string) => AppConfig | {} }
> = {
  [Environment.LOCAL]: () => require('./local'),
  [Environment.STAGING]: () => require('./staging'),
  [Environment.TESTING]: () => require('./testing'),
  [Environment.PRODUCTION]: () => require('./production'),
};

const environment = getEnvironmentOrDefault(Environment.LOCAL);

export const {
  config,
  getIdpOverrides,
}: { config: AppConfig; getIdpOverrides: (idp: string) => AppConfig | {} } =
  ENVIRONMENT_CONFIGS[environment as AppEnvironment]();
